import angular from 'angular';

/*@ngInject*/
function registerApiErrorHandlers(API, $log) {
  API.onError(function displayAlertOnApiError(message) {
    alert(message);
  });

  API.onSuccess(function logApiSuccessMessage(message) {
    $log.info(message);
  });
}

export default angular
  .module('wcMobile.apiErrorHandlers', [])
  .run(registerApiErrorHandlers);
