import angular from 'angular';

import AuthenticationService from './Authentication';
import ApiInterceptors from './ApiInterceptors';
import ApiErrorHandlerService from './ApiErrorHandler';
import Navigation from './Navigation';
import Search from './Search';

export default angular.module('wcMobile.services', [
  AuthenticationService.name,
  ApiInterceptors.name,
  ApiErrorHandlerService.name,
  Navigation.name,
  Search.name
]);
