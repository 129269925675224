import angular from 'angular';

import redirect from '../../../common/services/Redirect';
import states from '../../services/States';

/*@ngInject*/
function configureRoutes($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state(states.MEMBERS, {
      abstract: true,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfNotCompleteMember();
        }
      },
      template: `<ui-view class="WcRootView"></ui-view>`,
      url: '/members'
    })

    .state(states.REGISTRATION, {
      resolve: {
        /*@ngInject*/
        redirect: function($q, RedirectService) {
          return RedirectService.redirectIfNotMemberOrAlreadyComplete();
        }
      },
      template: `<wcm-registration wcm-scrollable-body></wcm-registration>`,
      abstract: true,
      url: `^/members/registration`
    })

    .state(states.REGISTRATION_PROFILE, {
      template: `<wcm-registration wcm-scrollable-body></wcm-registration>`,
      params: { currentPage: 0 },
      url: `^/members/registration/profile`
    })

    .state(states.REGISTRATION_EXPERIENCE, {
      template: `<wcm-registration wcm-scrollable-body></wcm-registration>`,
      params: { currentPage: 1 },
      url: `^/members/registration/experience/current-position`
    })

    .state(states.REGISTRATION_REMUNERATION, {
      template: `<wcm-registration wcm-scrollable-body></wcm-registration>`,
      params: { currentPage: 2 },
      url: `^/members/registration/remuneration`
    })

    .state(states.REGISTRATION_ABOUT, {
      template: `<wcm-registration wcm-scrollable-body></wcm-registration>`,
      params: { currentPage: 3 },
      url: `^/members/registration/about-you/aspirations`
    })

    .state(states.MEMBERS_REGISTRATION_COMPLETE, {
      template: `<wcm-registration-complete></wcm-registration-complete>`,
      url: `/registration-complete`
    })

    .state(states.MEMBERS_OWN_PROFILE, {
      template: `<wcm-profile is-own="true"></wcm-profile>`,
      url: `/profile`
    })

    .state(states.MEMBERS_PROFILE, {
      template: `<wcm-profile></wcm-profile>`,
      url: `/members/:id`
    })

    .state(states.MEMBERS_CHAT, {
      template: `<wcm-messenger></wcm-messenger>`,
      url: `/messages`
    })

    .state(states.MEMBERS_CONVERSATION, {
      template: `<wcm-messenger-conversation></wcm-messenger-conversation>`,
      url: `/messages/:id`
    })

    .state(states.MEMBERS_SEARCH, {
      template: `<wcm-search></wcm-search>`,
      url: `/search`
    })

    .state(states.MEMBERS_SETTINGS, {
      template: `<wcm-settings></wcm-settings>`,
      url: `/settings`
    });

  $urlRouterProvider.when(
    '/members/registration',
    '/members/registration/profile'
  );
  $urlRouterProvider.when(
    '/members/registration/about-you',
    '/members/registration/about-you/aspirations'
  );
}

export default angular
  .module('wcMobile.views.members', [redirect.name])
  .config(configureRoutes);
