import angular from 'angular';

import states from '../../../services/States';
import './styles.scss';
import templateUrl from './template.html';

class RegistrationCompleteController {
  /*@ngInject*/
  constructor($state) {
    this.$state = $state;
  }

  goToSettings() {
    this.$state.go(states.MEMBERS_SETTINGS);
  }
}

export default angular
  .module('wcMobile.components.registration.complete', [])
  .component('wcmRegistrationComplete', {
    controller: RegistrationCompleteController,
    templateUrl: templateUrl
  });
