import angular from 'angular';
import template from './template.html';
import './styles.scss';

/*@ngInject*/
function cityTypeahead($document, $timeout, $window) {
  return {
    restrict: 'E',
    scope: {
      existingValue: '=?wcValue',
      selectOptions: '=?wcOptions',
      showGoogleLogo: '<',
      wcOnSelect: '&',
      notFound: '=?wcNotFound'
    },
    templateUrl: template,
    transclude: true,
    link: function(scope, element) {
      let elementTop;
      let spacerElement = angular.element('<div></div>')[0];
      spacerElement.style.clear = 'both';
      $timeout(() => {
        elementTop = element[0].getBoundingClientRect().top + $window.scrollY;
        spacerElement.style.height = elementTop + 'px';

        element.find('input').on('blur', () => {
          spacerElement.style.height = '0px';
        });
      });

      element.on('focus', () => {
        $document[0].body.append(spacerElement);
        spacerElement.style.height = elementTop + 'px';
        $window.scrollTo(0, elementTop);
      });

      scope.$on('$destroy', () => {
        spacerElement.remove();
      });
    }
  };
}

export default angular
  .module('wcMobile.components.inputs.selects.cityTypeahead', [])
  .directive('wcmCityTypeahead', cityTypeahead);
