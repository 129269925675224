import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

class PageHeaderController {
  $onInit() {
    this.totalTime = this.pages.reduce(
      (previous, next) => previous + next.estimatedTimeMins,
      0
    );
  }

  calculateProgress() {
    return Math.round((this.currentPage / this.pages.length) * 100);
  }

  formatEstimatedTime() {
    const elapsedTime = this.pages
      .slice(0, this.currentPage)
      .reduce((previous, next) => previous + next.estimatedTimeMins, 0);

    const minsLeft = this.totalTime - elapsedTime;

    if (minsLeft === 1) {
      return '1 minute left';
    }

    return `${minsLeft} minutes left`;
  }
}

export default angular
  .module('wcMobile.components.registration.pageHeader', [])
  .component('wcmPageHeader', {
    bindings: {
      pages: '<',
      currentPage: '<',
      loading: '<'
    },
    controller: PageHeaderController,
    templateUrl: templateUrl
  });
