import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class controller {
  /*@ngInject*/
  constructor(MessengerService) {
    this.MessengerService = MessengerService;
  }
}

export default angular
  .module('wcMobile.components.navbar.messagesLink', [])
  /*@ngInject*/
  .directive('wcmNavbarMessagesLink', function() {
    return {
      controller: controller,
      controllerAs: 'ctrl',
      replace: true,
      restrict: 'E',
      scope: {
        state: '='
      },
      transclude: true,
      templateUrl: templateUrl
    };
  });
