import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wcMobile.components.forms.inputContainer', [])
  .component('wcmInputContainer', {
    templateUrl: templateUrl,
    transclude: true,
    bindings: {
      noFloat: '@'
    }
  });
