import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wcCommon.components.buttons.messengerSend', [])
  .component('wcMessengerSendButton', {
    bindings: {
      onClick: '&'
    },
    templateUrl: templateUrl
  });
