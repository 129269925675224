import angular from 'angular';

import './styles.scss';
import authenticationHeader from './authenticationHeader';
import changePassword from './changePassword';
import forgottenHelpText from './forgottenHelpText';
import forgottenPassword from './forgottenPassword';
import login from './login';
import './main';
import passwordCreation from './passwordCreation';
import phoneNumberInput from './phoneNumberInput';
import requestNewToken from './requestNewToken';
import token from './token';

export default angular.module('wcMob.components.authentication', [
  authenticationHeader.name,
  changePassword.name,
  forgottenHelpText.name,
  forgottenPassword.name,
  login.name,
  passwordCreation.name,
  phoneNumberInput.name,
  requestNewToken.name,
  token.name
]);
