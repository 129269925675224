import angular from 'angular';

import navigation from '../../../services/Navigation';
import './styles.scss';
import templateUrl from './template.html';

class BackButtonController {
  /*@ngInject*/
  constructor($state, $window, Navigation) {
    this.$state = $state;
    this.$window = $window;
    this.Navigation = Navigation;
  }

  onClick() {
    if (this.Navigation.hasNavigated) {
      this.$window.history.back();
    } else {
      this.$state.go(this.defaultSref);
    }
  }
}

export default angular
  .module('wcMobile.components.buttons.backButton', [navigation.name])
  .component('wcmBackButton', {
    bindings: {
      defaultSref: '@'
    },
    controller: BackButtonController,
    templateUrl: templateUrl
  });
