import { MESSAGES_CHANGED_EVENT } from '../../../Messenger.service';

export default class ConversationSummaryController {
  /*@ngInject*/
  constructor($state, $scope, API, ChatService, MessengerService) {
    this.$state = $state;
    this.$scope = $scope;
    this.ChatService = ChatService;
    this.MessengerService = MessengerService;
    this.API = API;
    this.isActive = this.isActive.bind(this);
  }

  $onInit() {
    this.readConversationMembers();
    this.conversationState = this.MessengerService.getConversationState();
    this.conversationId = this.conversation.sid;

    this.refreshLastMessageDate();
    this.refreshLastMessage();
    this.refreshLastMessageHasBeenRead();
    this.$scope.$on(MESSAGES_CHANGED_EVENT, () => {
      this.refreshLastMessageDate();
      this.refreshLastMessage();
      this.refreshLastMessageHasBeenRead();
    });
  }

  async readConversationMembers() {
    this.recipient = await this.MessengerService.getRecipient(
      this.conversation
    );
    this.userId = this.recipient ? this.recipient.identity : null;
    this.$scope.$apply();
  }

  async refreshLastMessageDate() {
    this.lastMessageDate = await this.ChatService.getLastMessageDate(
      this.conversation
    );
    if (!this.lastMessageDate) {
      this.lastMessageDate = this.conversation.dateCreated;
    }
  }

  async refreshLastMessage() {
    this.lastMessage = await this.getLastMessage();
    this.$scope.$apply();
  }

  async refreshLastMessageHasBeenRead() {
    this.lastMessageHasBeenRead = await this.fetchLastMessageHasBeenRead();
  }

  async getLastMessage() {
    return await this.ChatService.getLastMessageBody(this.conversation);
  }

  isActive() {
    return this.$state.params.id === this.conversationId;
  }

  async fetchLastMessageHasBeenRead() {
    if (this.conversation === this.MessengerService.currentConversation) {
      return true;
    }
    return await this.ChatService.lastMessageHasBeenRead(this.conversation);
  }
}
