import angular from 'angular';
import states from '../../../services/States';

class TokenController {
  /*@ngInject*/
  constructor($stateParams, AuthenticationService) {
    this.AuthenticationService = AuthenticationService;

    this.AuthenticationService.loginWithOneTimeToken(
      $stateParams,
      states.REQUEST_NEW_TOKEN
    ).then(this._onLoginSuccess.bind(this));
  }

  _onLoginSuccess() {
    this.AuthenticationService.navigateToDefaultState();
  }
}

export default angular
  .module('wcMob.components.authentication.token', [])
  .component('wcmToken', {
    controller: TokenController
  });
