import angular from 'angular';

class Navigation {
  constructor() {
    this.hasNavigated = false;
  }
}

/*@ngInject*/
function initNavigationService($rootScope, Navigation) {
  $rootScope.$on(
    '$stateChangeSuccess',
    (event, toState, toParams, fromState) => {
      // Don't update hasNavigated on first $stateChangeSuccess.
      if (fromState.name === '') {
        return;
      }

      Navigation.hasNavigated = true;
    }
  );
}

export default angular
  .module('wcMobile.services.navigation', [])
  .service('Navigation', Navigation)
  .run(initNavigationService);
