import angular from 'angular';

import states from '../../services/States';
import clientNavbar from './clientNavbar';
import memberNavbar from './memberNavbar';
import messagesLink from './messagesLink';
import templateUrl from './template.html';

class NavbarController {
  /*@ngInject*/
  constructor($state, AuthenticationService, MessengerService) {
    this.$state = $state;
    this.AuthenticationService = AuthenticationService;
    this.MessengerService = MessengerService;

    this.hideOnStates = [
      states.AUTHENTICATION,
      states.CLIENTS_CONVERSATION,
      states.MEMBERS_CONVERSATION,
      states.MEMBERS_REGISTRATION_COMPLETE,
      states.NOT_FOUND,
      states.PASSWORD_CREATION,
      states.CHANGE_PASSWORD,
      states.REGISTRATION,
      states.SERVER_ERROR
    ];
  }

  shouldShow() {
    return (
      this.$state.current.name !== '' &&
      !this.hideOnStates.some(state => this.$state.includes(state))
    );
  }
}

export default angular
  .module('wcMobile.components.navbar', [
    clientNavbar.name,
    memberNavbar.name,
    messagesLink.name
  ])
  .component('wcmNavbar', {
    controller: NavbarController,
    templateUrl: templateUrl
  });
