import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { MinimalPositionOverview } from '../../minimal-position-overview/MinimalPositionOverview';
import { ReactChips } from './../../../../../../desktop/components/react/ReactChips/ReactChips';
import { RemunerationOverview } from './../../../remuneration-overview/RemunerationOverview';

/*eslint complexity: ["error", 30]*/
export const PositionOverview = ({
  position,
  profile,
  remuneration,
  showEmpty,
  isClient = false,
  CurrencyService,
  CompensationsBlockedService
}) => {
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  const expandSummary = () => {
    setSummaryExpanded(state => !state);
  };

  return (
    <div className="PositionOverview">
      <div className="PositionOverview__Summary">
        <MinimalPositionOverview position={position} />
      </div>
      {(position.roleSummary || showEmpty) && (
        <div className="PositionOverview__Expandable" onClick={expandSummary}>
          <div className="PositionOverview__ExpandableContainer">
            <div
              className={`PositionOverview__RoleSummary ${
                !summaryExpanded ? 'PositionOverview__RoleSummary--clamped' : ''
              }`}
            >
              <div className="PositionOverview__Title">Role Summary</div>
              {!position.roleSummary && showEmpty && (
                <div className="PositionOverview__RoleSummaryText--empty">
                  Detail your role and responsibilities to help client companies
                  and members understand more about this position. The more
                  information you give here, the more you will be found for the
                  right roles and opportunities.
                </div>
              )}
              {position.roleSummary && !summaryExpanded && (
                // eslint-disable-next-line
                <div className="PositionOverview__RoleSummaryText" clamp="2">
                  {position.roleSummary}
                </div>
              )}
              {position.roleSummary && summaryExpanded && (
                <div className="PositionOverview__RoleSummaryText">
                  {position.roleSummary}
                </div>
              )}
            </div>
          </div>
          {position.roleSummary && (
            <div
              className="md-icon-button PositionOverview__ExpandButton"
              aria-label="Expand Role Summary"
            >
              <span
                className={
                  !summaryExpanded
                    ? 'zmdi zmdi-chevron-down'
                    : 'zmdi zmdi-chevron-up'
                }
              />
            </div>
          )}
        </div>
      )}
      <div className="PositionOverview__Details">
        {position.businessLines.length && (
          <div className="PositionOverview__Chips">
            <ReactChips
              title="Lines of Business"
              data={position.businessLines}
              readOnly
              accent
            />
          </div>
        )}
        {(position.directReportingLine || showEmpty) && (
          <div className="PositionOverview__Chips">
            <ReactChips
              title="Direct Reporting Line"
              data={[{ name: position.directReportingLine }]}
              readOnly
              accent
            />
          </div>
        )}
        {position.current && position.noticePeriod && (
          <div className="PositionOverview__Chips">
            <ReactChips
              title="Notice Period"
              data={[{ name: position.noticePeriod }]}
              readOnly
              accent
            />
          </div>
        )}
      </div>
      {((remuneration && profile && !profile.noCompensation) || isClient) && (
        <RemunerationOverview
          remuneration={remuneration}
          CurrencyService={CurrencyService}
          CompensationsBlockedService={CompensationsBlockedService}
        />
      )}
    </div>
  );
};

PositionOverview.propTypes = {
  position: PropTypes.object,
  profile: PropTypes.object,
  showEmpty: PropTypes.bool,
  remuneration: PropTypes.object,
  isClient: PropTypes.bool,
  CurrencyService: PropTypes.any,
  CompensationsBlockedService: PropTypes.any
};
