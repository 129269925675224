import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const MemberProfileDocuments = ({
  longlist,
  documents,
  DocumentDownloadService
}) => {
  const download = doc => {
    DocumentDownloadService.download(doc, 'candidatedocuments');
  };

  return documents && documents.length ? (
    <article className="Separator">
      <div>
        <h2 className="WcMemberProfileContent__Project">
          Project: {longlist && longlist.name ? longlist.name : ''}
        </h2>

        {documents &&
          documents.length &&
          documents.map((document, index) => (
            <div key={index} className="WcMemberProfileContent__Documents">
              <span className="WcMemberProfileContent__Document__Name">
                {document.name}
              </span>
              <span
                className="WcMemberProfileContent__Document__Link"
                onClick={() => download(document)}
              >
                Download
              </span>
            </div>
          ))}
      </div>
    </article>
  ) : null;
};

MemberProfileDocuments.propTypes = {
  longlist: PropTypes.object,
  documents: PropTypes.arrayOf(PropTypes.object),
  DocumentDownloadService: PropTypes.any
};
