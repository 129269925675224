import angular from 'angular';

import redirect from '../../../common/services/Redirect';
import states from '../../services/States';
import './styles.scss';

/*@ngInject*/
function configureRoutes($stateProvider) {
  $stateProvider
    .state(states.AUTHENTICATION, {
      abstract: true,
      template: `<ui-view class="WcmAuthenticationRoot"></ui-view>`,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfAlreadyLoggedIn();
        }
      }
    })

    .state(states.CHANGE_PASSWORD, {
      url: '/change-password/:token',
      template:
        '<wcm-change-password class="WcmAuthenticationView"></wc-change-password>'
    })

    .state(states.FORGOTTEN_PASSWORD, {
      url: '/forgotten-password',
      template:
        '<wcm-forgotten-password class="WcmAuthenticationView"></wc-forgotten-password>'
    })

    .state(states.LOGIN, {
      url: '/login?status&next',
      template: `<wcm-login class="WcmAuthenticationView"></wcm-login>`
    })

    .state(states.TOKEN_LEGACY, {
      url: '/token/:token?redirectTo&changedBy',
      template: `<wcm-token class="WcmAuthenticationView"></wcm-token>`
    })

    .state(states.TOKEN_REGISTER, {
      url: '/token-register/:token?redirectTo&changedBy',
      template: `<wcm-token class="WcmAuthenticationView"></wcm-token>`
    })

    .state(states.TOKEN_REDIRECT, {
      url: '/token-redirect/:token?redirectTo&changedBy',
      template: `<wcm-token class="WcmAuthenticationView"></wcm-token>`
    })

    .state(states.TOKEN_LOGIN, {
      url: '/token-login/:token?redirectTo&changedBy',
      template: `<wcm-token class="WcmAuthenticationView"></wcm-token>`
    })

    .state(states.REQUEST_NEW_TOKEN, {
      url: '/request-new-token',
      template: `<wcm-request-new-token class="WcmAuthenticationView"></wcm-request-new-token>`
    })

    .state(states.PASSWORD_CREATION, {
      url: '/password-creation',
      template: `<wcm-create-password class="WcmAuthenticationRoot WcmAuthenticationView"></wcm-create-password>`,
      resolve: {
        /*@ngInject*/
        redirect: function($state, AuthenticationService) {
          return AuthenticationService.getAuthenticatedUser().then(() => {
            if (
              !AuthenticationService.hasUser() ||
              !AuthenticationService.isMember() ||
              AuthenticationService.hasChosenPassword()
            ) {
              $state.go(states.LOGIN);
            }
          });
        }
      }
    });
}

export default angular
  .module('wcMobile.views.authentication', [redirect.name])
  .config(configureRoutes);
