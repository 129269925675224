import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';
import MessageBubbleController from '../MessageBubble.controller';

export default angular
  .module(
    'wc.components.messenger.conversationPane.messageBubble.messageBubblePartner',
    []
  )
  .component('wcMessageBubblePartner', {
    NAME: 'wcMessageBubblePartner',
    bindings: {
      isFirst: '<',
      isLast: '<',
      message: '<',
      mergeBottom: '<',
      mergeTop: '<',
      sentAt: '<'
    },
    controller: MessageBubbleController,
    templateUrl: templateUrl
  });
