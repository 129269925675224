import angular from 'angular';
import uiRouter from 'angular-ui-router';

import authentication from '../services/Authentication';
import states from '../services/States';
import authenticationView from './authentication';
import clientsView from './clients';
import membersView from './members';
import compatibilityRedirects from './compatibilityRedirections';

/*@ngInject*/
function configureRoutingDefaults($stateProvider, $urlRouterProvider) {
  // The "root" state immediately redirects to an appropriate route in the app.
  $stateProvider.state(states.ROOT, {
    url: '/',
    resolve: {
      /*@ngInject*/
      redirect: function(AuthenticationService) {
        return AuthenticationService.navigateToDefaultState();
      }
    }
  });

  $stateProvider.state(states.NOT_FOUND, {
    url: '/404',
    template: '<wc-not-found></wc-not-found>'
  });

  $stateProvider.state(states.SERVER_ERROR, {
    url: '/500',
    template: '<wc-server-error></wc-server-error>'
  });

  // Without this `.when()` rule, the root state will only be matched with an explicit trailing
  // slash in the URL.
  $urlRouterProvider.when('', '/');

  // TODO: This should ideally be the 404 page. For now, we're not 100% confident about redirection
  // from desktop to mobile, and the default page is better than a 404, so let's redirect to default
  // if the URL doesn't match.
  $urlRouterProvider.otherwise('/');
}

/*@ngInject*/
function logStateChangeErrors($log, $rootScope) {
  // Uncomment these to debug state transition errors :) / :(

  // $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {
  //   console.log('$stateChangeStart from', fromState.name, 'to', toState.name);
  // });

  // $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState) {
  //   console.log('$stateChangeSuccess from', fromState.name, 'to', toState.name);
  // });

  $rootScope.$on('$stateChangeError', function(
    event,
    toState,
    toParams,
    fromState,
    fromParams,
    error
  ) {
    $log.error('$stateChangeError', error);
  });
}

export default angular
  .module('wcMobile.views', [
    uiRouter,
    authentication.name,
    authenticationView.name,
    clientsView.name,
    membersView.name,
    compatibilityRedirects.name
  ])
  .config(configureRoutingDefaults)
  .run(logStateChangeErrors);
