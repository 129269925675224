import './MessengerContacts.scss';
import templateUrl from './MessengerContacts.html';

export default {
  bindings: {
    conversations: '<'
  },
  NAME: 'wcMessengerContacts',
  templateUrl: templateUrl
};
