import './ConversationList.scss';
import templateUrl from './ConversationList.html';

export default {
  bindings: {
    conversations: '<'
  },
  templateUrl: templateUrl,
  NAME: 'wcMessengerConversationList'
};
