import angular from 'angular';

import getFormattedDateString from '../../../../common/services/DateUtils';
import { POSITION_TYPES } from '../../../../common/services/Positions';
import templateUrl from './template.html';

class ExperienceFormController {
  /*@ngInject*/
  constructor(
    $q,
    $window,
    API,
    CurrencyService,
    PlaceSearchService,
    GoogleMapService
  ) {
    this.$q = $q;
    this.$window = $window;
    this.API = API;
    this.PlaceSearchService = PlaceSearchService;
    this.GoogleMapService = GoogleMapService;
    this.formatCurrency = CurrencyService.formatCurrency;
    this.POSITION_TYPES = POSITION_TYPES;

    // Set suggestions to null by default, to tell if the company has changed
    this.suggestions = null;
    this.notFound = false;
    this.showHint = false;

    this.citySuggestions = null;
    this.cityNotFound = false;
    this.cityShowHint = false;
  }

  $onInit() {
    this.companySearchTerm =
      this.position && this.position.company ? this.position.company.name : '';
    this.position.positionType = this.position.positionType
      ? this.position.positionType
      : POSITION_TYPES.FULL_TIME;
    this.position.current =
      this.position.current === null ? true : this.position.current;

    this.citySearchTerm = this.position ? this.position.city : '';

    // Mock an input element which is required by Google Places Service
    let mockInput = angular.element('<place-input/>')[0];
    this.GoogleMapService.get().then(gmaps => {
      this.service = new gmaps.maps.places.PlacesService(mockInput);
    });
  }

  submit() {
    this._validateCompanyInput();
    this._validateCityInput();
    if (!this.form.$valid) {
      return;
    }

    this.pendingSubmit = true;

    function updatePosition() {
      let hasPosition = false;
      let previousPosition = null;
      if (this.profile.positions.length) {
        hasPosition = true;
        previousPosition = this.API.restangularizeUrl(
          this.profile.positions[0].url
        );
      }

      const currentPositionData = {
        positionType: this.position.positionType,
        company: this.position.company ? this.position.company.id : null,
        city: this.position.city ? this.position.city : null,
        startDate: getFormattedDateString(this.position.startDate),
        internalJobTitle: this.position.internalJobTitle,
        endDate: this.position.current
          ? null
          : getFormattedDateString(this.position.endDate), // Remove end date if changed to current
        current: this.position.current
      };

      if (this.position.cityCoords) {
        currentPositionData['cityCoords'] = this.position.cityCoords;
      }

      if (hasPosition) {
        return this.API.restangularize(previousPosition).patch(
          currentPositionData
        );
      } else {
        return this.API.Positions.post(currentPositionData).then(position => {
          this.profile.positions = [position];
        });
      }
    }

    const patchMember = {
      capexAmount: this.profile.capexAmount ? this.profile.capexAmount : null,
      capexCurrency: this.profile.currency.code,
      opexAmount: this.profile.opexAmount ? this.profile.opexAmount : null,
      opexCurrency: this.profile.currency.code,
      profitAndLossAmount: this.profile.profitAndLossAmount
        ? this.profile.profitAndLossAmount
        : null,
      profitAndLossCurrency: this.profile.currency.code,
      revenueTargetAmount: this.profile.revenueTargetAmount
        ? this.profile.revenueTargetAmount
        : null,
      revenueTargetCurrency: this.profile.currency.code,
      extendedTeamSize: this.profile.extendedTeamSize
        ? this.profile.extendedTeamSize
        : null
    };

    this.API.restangularize(this.profile)
      .patch(patchMember)
      .then(updatePosition.bind(this))
      .then(
        () => {
          this.onSubmit();
          this.pendingSubmit = false;
        },
        () => {
          this.$window.alert('Failed to submit experience. Please try again.');
          this.pendingSubmit = false;
        }
      );
  }

  onBlur() {
    this.showHint = false;
    this._validateCompanyInput();
  }

  onFocus() {
    this.showHint = true;
    this._resetCompanyInputValidation();
  }

  cityOnBlur() {
    this.cityShowHint = false;
    this._validateCityInput();
  }

  cityOnFocus() {
    this.cityShowHint = true;
    this._resetCityInputValidation();
  }

  searchCompany(searchTerm) {
    delete this.position.company; // clear company if new search started
    if (searchTerm) {
      this.API.Companies.getList({ limit: 5, search: searchTerm })
        .then(resp => {
          this.suggestions = resp;
          this.notFound = !this.suggestions.length;
        })
        .catch(() => {
          this.notFound = true;
          this.suggestions = [];
        });
    }
  }

  selectCompany(company) {
    this._setCompany(company);
    this.suggestions = null;
    this._validateCompanyInput('select');
  }

  _setCompany(company) {
    this.companySearchTerm = company.name;
    this.position.company = company;
  }

  _validateCompanyInput() {
    let valid = !!(
      this.position &&
      this.position.company &&
      this.suggestions === null
    );
    this.form.company.$setValidity('selectFromOptions', valid);
  }

  _resetCompanyInputValidation() {
    this.form.company.$setValidity('selectFromOptions', true);
  }

  searchCity(searchTerm) {
    this.position.cityCoords = null;
    if (searchTerm) {
      this.PlaceSearchService.autocomplete(searchTerm)
        .then(resp => {
          this.cityNotFound = false;
          this.citySuggestions = resp;
        })
        .catch(() => {
          this.cityNotFound = true;
          this.citySuggestions = [];
        });
    }
  }

  selectCity(city) {
    this._setCity(city);
    this.citySuggestions = [];
  }

  _setCity(city) {
    this.citySearchTerm = this.position.city =
      city.structured_formatting.main_text;
    this._getDetails(city).then(details => {
      this._setDetails(details);
      this._validateCityInput();
    });
  }

  _getDetails(city) {
    return this.PlaceSearchService.getDetailsWithService(
      this.service,
      city.place_id
    );
  }

  _setDetails(details) {
    this.position.cityCoords = {
      lat: details.geometry.location.lat(),
      lng: details.geometry.location.lng()
    };
  }

  _validateCityInput() {
    if (
      !this.position ||
      (!this.position.cityCoords && this.citySuggestions !== null)
    ) {
      this.form.city.$setValidity('selectFromOptions', false);
    } else {
      this.form.city.$setValidity('selectFromOptions', true);
    }
  }

  _resetCityInputValidation() {
    this.form.city.$setValidity('selectFromOptions', true);
  }
}

export default angular
  .module('wcMobile.components.registration.experienceForm', [])
  .component('wcmRegistrationExperienceForm', {
    bindings: {
      currencies: '<',
      onPrevious: '&',
      onSubmit: '&',
      position: '=',
      profile: '='
    },
    controller: ExperienceFormController,
    templateUrl: templateUrl
  });
