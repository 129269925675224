import angular from 'angular';
import states from '../services/States';

/*@ngInject*/
function ApiInterceptors($state, AuthenticationService, WcCustomRestangulars) {
  WcCustomRestangulars.all().forEach(restangular => {
    restangular.addErrorInterceptor(data => {
      // Slightly cheeky: often there are many 401s at once, and some of them resolve when
      // redirection to LOGIN has already happened. To prevent us from ending up with
      // `?next=/login` and losing the "real" `next` param, don't redirect if the app is already
      // on the LOGIN screen.
      if (
        AuthenticationService.isLoggedIn() &&
        data.status === 401 &&
        $state.current.name !== states.LOGIN &&
        $state.current.name !== states.ROOT &&
        $state.current.name !== ''
      ) {
        AuthenticationService.redirectToLogin();
      }

      if (data.status === 401) {
        AuthenticationService.cleanSessionData();
      }

      return data;
    });
  });
}

export default angular
  .module('wc.config.apiInterceptors', [])
  .run(ApiInterceptors);
