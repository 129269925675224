import angular from 'angular';

import states from '../../../services/States';
import './styles.scss';
import templateUrl from './template.html';

class NoMessagesController {
  /*@ngInject*/
  constructor($state, AuthenticationService) {
    this.$state = $state;
    this.searchEnabled = false;
    this.AuthenticationService = AuthenticationService;
    this.profilePromise = this.AuthenticationService.getProfile().then(
      profile => {
        this.searchEnabled = profile && !profile.limitedClient;
      }
    );
  }

  onStartSearchingClick() {
    let page = states.MEMBERS_SEARCH;
    if (this.AuthenticationService.isClient()) {
      page = states.CLIENTS_SEARCH;
    }
    this.$state.go(page);
  }
}

export default angular
  .module('wcMobile.components.messenger.noMessages', [])
  .component('wcmMessengerNoMessages', {
    controller: NoMessagesController,
    templateUrl: templateUrl
  });
