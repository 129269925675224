import angular from 'angular';

import states from '../services/States';
import authentication from '../services/Authentication';

/*@ngInject*/
function configureRedirects($urlRouterProvider) {
  $urlRouterProvider.when(
    /\/conversation\/(.*)/,
    /*@ngInject*/ ($match, $state, AuthenticationService) => {
      let conversationState = AuthenticationService.isClient()
        ? states.CLIENTS_CONVERSATION
        : states.MEMBERS_CONVERSATION;

      $state.go(conversationState, { id: $match[1] });
    }
  );

  $urlRouterProvider.when(
    '/search',
    /*@ngInject*/ ($state, AuthenticationService) => {
      let searchState = AuthenticationService.isClient()
        ? states.CLIENTS_SEARCH
        : states.MEMBERS_SEARCH;

      $state.go(searchState);
    }
  );

  $urlRouterProvider.when(
    '/search',
    /*@ngInject*/ ($state, AuthenticationService) => {
      let searchState = AuthenticationService.isClient()
        ? states.CLIENTS_SEARCH
        : states.MEMBERS_SEARCH;

      $state.go(searchState);
    }
  );

  $urlRouterProvider.when(
    '/profiles/me',
    /*@ngInject*/ $state => {
      $state.go(states.MEMBERS_OWN_PROFILE);
    }
  );

  $urlRouterProvider.when(
    /\/profiles\/([0-9]+)/,
    /*@ngInject*/ ($match, $state, AuthenticationService) => {
      let profileState = AuthenticationService.isClient()
        ? states.CLIENTS_PROFILE
        : states.MEMBERS_PROFILE;

      $state.go(profileState, { id: $match[1] });
    }
  );
}

export default angular
  .module('wcMobile.views.compatibilityRedirects', [authentication.name])
  .config(configureRedirects);
