/*
 * This directive forces the Body's position to static
 * It's needed at pages, where other 3rd party libraries
 * (e.g. md-select, md-autocomplete in ngMaterial <1.1.4(+?))
 * disable scrolling by setting it to fixed
 */

import './styles.scss';

/*@ngInject*/
function ScrollableBody($document) {
  return {
    link: function() {
      angular.element($document[0].body).addClass('WcmScrollableBody');
    },
    restrict: 'A'
  };
}

const NAME = 'wcmScrollableBody';

export default angular
  .module('wcMobile.components.scrollableBody', [])
  .directive(NAME, ScrollableBody);
