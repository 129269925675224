import angular from 'angular';

import templateUrl from './template.html';

class OwnMemberProfileActionsController {
  /*@ngInject*/
  constructor(Resume) {
    this.Resume = Resume;
  }

  downloadResume() {
    if (this.member.resume) {
      this.Resume.download(this.member.resume, this.member.fullName);
    }
  }
}
export default angular
  .module('wcMobile.components.profile.profileActions.own', [])
  .component('wcmOwnMemberProfileActions', {
    bindings: {
      member: '<'
    },
    controller: OwnMemberProfileActionsController,
    templateUrl: templateUrl
  });
