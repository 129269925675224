import angular from 'angular';
import templateUrl from './template.html';
import './styles.scss';
import states from '../../services/States';

const RESULTS_PER_PAGE = 20;

class SearchController {
  /*@ngInject*/
  constructor($q, $scope, API, Search, observeOnScope, AuthenticationService) {
    this.Search = Search;
    this.$q = $q;
    this.API = API;
    this.states = states;
    this.debouncing = false;
    this.loading = false;
    this.members = [];
    this.exhaustedResults = true;
    let _this = this;
    this.AuthenticationService = AuthenticationService;

    observeOnScope($scope, '$ctrl.Search.search')
      .do(() => {
        _this.debouncing = true;
      })
      .debounce(400)
      .distinctUntilChanged()
      .subscribe(query => {
        _this.loading = true;
        _this.debouncing = false;
        _this.page = 0;
        _this.members = [];
        _this._cancelExistingRequests();

        if (query.newValue === '') {
          _this._setMembers([]);
          $scope.$digest();
          return;
        }

        API.MembersTextSearch.withHttpConfig({
          timeout: _this.requestCanceller.promise
        })
          .getList({
            search: query.newValue,
            offset: 0,
            limit: RESULTS_PER_PAGE
          })
          .then(_this._setMembers.bind(_this))
          .catch(() => {});
      });
  }

  getProfileStateByUserType(memberId) {
    if (this.AuthenticationService.isClient()) {
      return `${states.CLIENTS_PROFILE}({id: ${memberId}})`;
    }
    return `${states.MEMBERS_PROFILE}({id: ${memberId}})`;
  }

  clearSearch() {
    this.Search.search = '';
  }

  loadNextMembers() {
    // don't load the next page if a request is pending
    if (this.requestCanceller || this.exhaustedResults) {
      return;
    }
    this.requestCanceller = this.$q.defer();

    this.loading = true;
    this.API.MembersTextSearch.withHttpConfig({
      timeout: this.requestCanceller.promise
    })
      .getList({
        search: this.query,
        offset: this.page * RESULTS_PER_PAGE,
        limit: RESULTS_PER_PAGE
      })
      .then(this._setMembers.bind(this))
      .catch(() => {});
  }

  _cancelExistingRequests() {
    if (this.requestCanceller) {
      this.requestCanceller.resolve();
    }

    this.requestCanceller = this.$q.defer();
  }

  _setMembers(members) {
    this.members = this.members.concat(members);

    if (members.length < RESULTS_PER_PAGE) {
      this.exhaustedResults = true;
    } else {
      this.exhaustedResults = false;
    }

    this.loading = false;
    this.requestCanceller = null;
    this.page += 1;
  }
}

export default angular
  .module('wcMobile.components.search', [])
  .component('wcmSearch', {
    controller: SearchController,
    templateUrl: templateUrl
  });
