import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class ClientDocumentDownloadController {
  /*@ngInject*/
  constructor(API, $stateParams, DocumentDownloadService) {
    this.API = API;
    this.DocumentDownloadService = DocumentDownloadService;

    API.Documents.get($stateParams.id)
      .then(this.setDocument.bind(this))
      .catch(this.handleError.bind(this));
  }

  setDocument(clientDocument) {
    this.document = clientDocument;
  }

  downloadDocument() {
    this.DocumentDownloadService.download(this.document, 'documents');
  }

  handleError(error) {
    this.API.handleError()(error);
  }
}

export default angular
  .module('wcMobile.components.clientDocumentDownload', [])
  .component('wcmClientDocumentDownload', {
    controller: ClientDocumentDownloadController,
    templateUrl: templateUrl
  });
