import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

import { USER_TYPES } from '../../../../../common/services/api/APIConstants';

import States from '../../../../services/States';

// TODO: Could do with moving most of the functionality to a service as it is duplicated in desktop
class ConversationHeaderController {
  /*@ngInject*/
  constructor($scope, $state, MessengerService, AuthenticationService) {
    this.$scope = $scope;
    this.$state = $state;
    this.MessengerService = MessengerService;
    this.AuthenticationService = AuthenticationService;

    this.conversationListState = States.MEMBERS_CHAT;
    this.profileState = States.PROFILE;

    $scope.$watch(
      () => this.MessengerService.currentConversation,
      this.updateRecipient.bind(this)
    );
  }

  async updateRecipient() {
    if (!this.MessengerService.currentConversation) {
      return;
    }

    this.recipient = await this.MessengerService.getRecipient(
      this.MessengerService.currentConversation
    );
    this.$scope.$apply();
  }

  canNavigateToProfile() {
    return (
      this.MessengerService.getUser() &&
      this.recipient &&
      this.recipient.attributes &&
      this.recipient.attributes.profile_type === USER_TYPES.MEMBER
    );
  }

  // This won't be needed when Client profiles are added, then any User can see any other User.
  getUiSref() {
    if (this.canNavigateToProfile()) {
      if (this.AuthenticationService.isClient()) {
        return `${States.CLIENTS_PROFILE}({id: ${
          this.recipient.attributes.profile_id
        }})`;
      }
      return `${States.MEMBERS_PROFILE}({id: ${
        this.recipient.attributes.profile_id
      }})`;
    }

    return '.';
  }
}

export default angular
  .module('wcMobile.components.messenger.conversation.header', [])
  .component('wcmMessengerConversationHeader', {
    controller: ConversationHeaderController,
    templateUrl: templateUrl
  });
