import angular from 'angular';

import './actions';
import form from './form';
import inputContainer from './inputContainer';

export default angular.module('wcMobile.components.forms', [
  inputContainer.name,
  form.name
]);
