import angular from 'angular';

import templateUrl from './template.html';

class ClientMemberProfileActionsController {
  /*@ngInject*/
  constructor(Resume, MessengerService, AuthenticationService) {
    this.Resume = Resume;
    this.MessengerService = MessengerService;
    this.AuthenticationService = AuthenticationService;
    this.messagingEnabled = false;
    this.AuthenticationService.getProfile().then(profile => {
      this.messagingEnabled = profile && !profile.disableMessaging;
    });
  }

  downloadResume() {
    if (this.member.resume) {
      this.Resume.download(this.member.resume, this.member.fullName);
    }
  }
}
export default angular
  .module('wcMobile.components.profile.profileActions.client', [])
  .component('wcmClientMemberProfileActions', {
    bindings: {
      member: '<'
    },
    controller: ClientMemberProfileActionsController,
    templateUrl: templateUrl
  });
