import 'angular';
import templateUrl from './template.html';
import './styles.scss';

import conversation from './conversation';
import noMessages from './noMessages';
import recipientInfo from './recipientInfo';

class MessengerController {
  /*@ngInject*/
  constructor(MessengerService) {
    this.MessengerService = MessengerService;
    this.loading = true;
    this.MessengerService.conversationsPromise.then(() => {
      this.loading = false;
    });

    this.MessengerService.serviceReadyPromise.then(() => {
      this.MessengerService.loadConversations();
    });
  }
}

export default angular
  .module('wcMobile.components.messenger', [
    conversation.name,
    noMessages.name,
    recipientInfo.name
  ])
  .component('wcmMessenger', {
    controller: MessengerController,
    templateUrl: templateUrl
  });
