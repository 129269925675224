import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';
import logo from '../../../../common/components/logo-mh/logo-mh.svg';

export default angular
  .module('wcMob.components.authentication.authenticationHeader', [])
  .component('wcmAuthenticationHeader', {
    /*@ngInject*/
    controller: function($sce) {
      this.logoSvg = $sce.trustAsHtml(logo);
    },
    templateUrl: templateUrl,
    transclude: true
  });
