import '../raven.init';
/* Third party */
import angular from 'angular';
import 'redux';
import ngRedux from 'ng-redux';
import 'restangular';
import 'rx-angular';
import ngInfiniteScroll from 'ng-infinite-scroll';
import ngSanitize from 'angular-sanitize';
import ngMessages from 'angular-messages';

import uiRouter from 'angular-ui-router';

/* Globally imported styles */
import 'normalize.css';
import '../common/styles/AngularMaterialOverrides.scss';
import '../common/styles/FontFaces.scss';

import './main.scss';

/* App modules */
import commonComponents from '../common/components';
import commonFilters from '../common/filters';
import commonServices from '../common/services';
import components from './components';
import config from './config';
import run from './run';
import services from './services';
import views from './views';

/* Config */
import ENV_CONFIG from './config/EnvConfig.constant';

const deps = [
  // Third party modules
  'angular-google-analytics',
  ngRedux,
  uiRouter,
  ngInfiniteScroll,
  ngSanitize,
  ngMessages,
  'ngMaterial',
  'restangular',
  'rx',

  // Common modules (shared with desktop)
  commonServices.name,
  commonComponents.name,
  commonFilters.name,

  // Local modules
  components.name,
  config.name,
  run.name,
  services.name,
  views.name
];

if (ENV_CONFIG.configName !== 'local') {
  deps.push('ngRaven');
}

export default angular.module('wcMobile', deps);
