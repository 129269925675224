import angular from 'angular';

import states from '../../../services/States';
import './styles.scss';
import templateUrl from './template.html';

class LoginController {
  /*@ngInject*/
  constructor($location, $stateParams, API, AuthenticationService) {
    this.$location = $location;
    this.$stateParams = $stateParams;
    this.API = API;
    this.AuthenticationService = AuthenticationService;

    this.forgottenPasswordState = states.FORGOTTEN_PASSWORD;
    this.loading = false;
  }

  login() {
    if (this.form.$valid) {
      this.loading = true;
      this.loginDisabled = true;
      this.AuthenticationService.login(this.email, this.password).then(
        () => {
          this.loading = false;
          if (this.$stateParams.next) {
            this._redirectToNextState();
          } else {
            this.AuthenticationService.navigateToDefaultState();
          }
        },
        () => {
          this.loading = false;
          this.loginDisabled = false;
        }
      );
    }
  }

  _redirectToNextState() {
    const nextLocation = decodeURIComponent(this.$stateParams.next);
    const [nextPath, nextSearch] = nextLocation.split('?');
    this.$location.path(nextPath).search(nextSearch || {});
  }
}

export default angular
  .module('wcMobile.components.authentication.login', [])
  .component('wcmLogin', {
    controller: LoginController,
    templateUrl: templateUrl
  });
