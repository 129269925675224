import angular from 'angular';

import templateUrl from './template.html';
import STATES from '../../../services/States';

class PasswordCreationController {
  /*@ngInject*/
  constructor($state, API, AuthenticationService) {
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;

    API.User.get().then(user => {
      this.user = user;
    });
  }

  getTermsHref() {
    if (this.AuthenticationService.isClient()) {
      return 'https://www.wintercircle.co/client-terms.html';
    }

    if (this.AuthenticationService.isMember()) {
      return 'https://www.wintercircle.co/member-terms.html';
    }
  }

  signUp() {
    if (this.form.$valid) {
      this.formErrorMessage = null;
      this.API.User.patch({
        password: this.user.password
      })
        // Refresh the AuthenticationService, because hasChosenPassword has changed now.
        .then(() => this.AuthenticationService.refresh())
        .then(() => this.$state.go(STATES.REGISTRATION_PROFILE))
        .catch(this.API.handleError());
    }
  }
}

export default angular
  .module('wcMob.components.authentication.passwordCreation', [])
  .component('wcmCreatePassword', {
    controller: PasswordCreationController,
    templateUrl: templateUrl
  });
