import states from '../services/States';

/*@ngInject*/
function configureMessengerService(
  $rootScope,
  AuthenticationService,
  featureFlags,
  MessengerService
) {
  if (!featureFlags.isOn('messaging')) {
    return;
  }

  //TODO move conversationState logic into service

  AuthenticationService.getAuthenticatedUser().then(user => {
    // Not logged in
    if (!user) {
      return;
    }
    let conversationState = AuthenticationService.isClient()
      ? states.CLIENTS_CONVERSATION
      : states.MEMBERS_CONVERSATION;
    let listState = AuthenticationService.isClient()
      ? states.CLIENTS_CHAT
      : states.MEMBERS_CHAT;
    MessengerService.init(user, {
      conversation: conversationState,
      list: listState,
      redirectListState: false
    });
  });

  $rootScope.$on('$stateChangeSuccess', function(
    event,
    toState,
    toParams,
    fromState
  ) {
    let conversationState = AuthenticationService.isClient()
      ? states.CLIENTS_CONVERSATION
      : states.MEMBERS_CONVERSATION;
    if (
      fromState.name === conversationState &&
      toState.name !== conversationState
    ) {
      MessengerService.leaveConversations();
    }
  });
}

export default angular
  .module('wcMobile.run.configureMessengerService', [])
  .run(configureMessengerService);
