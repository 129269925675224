import angular from 'angular';

import { MESSAGES_CHANGED_EVENT } from '../../../../common/components/messenger/Messenger.service';

import './styles.scss';
import templateUrl from './template.html';

import header from './header';

class ConversationController {
  /*@ngInject*/
  constructor($scope, MessengerService) {
    this.$scope = $scope;
    this.loading = true;
    this.MessengerService = MessengerService;
    this.MessengerService.serviceReadyPromise.then(() => {
      this.MessengerService.loadConversations();
    });
    this.autoscrollEvent = MESSAGES_CHANGED_EVENT;

    this.$scope.$on(MESSAGES_CHANGED_EVENT, () => {
      this.loading = this.MessengerService.currentMessages === null;
      this.$scope.$apply();
    });
  }
}

export default angular
  .module('wcMobile.components.messenger.conversation', [header.name])
  .component('wcmMessengerConversation', {
    controller: ConversationController,
    templateUrl: templateUrl
  });
