import 'ngstorage';

import { USER_TYPES } from '../../common/services/api/APIConstants';
import apiModule from '../../common/services/api/API.module';
import commonAuthenticationService from '../../common/services/CommonAuthentication';
import states from './States';
import search from './Search';

const CLIENT_TOKEN_LOGIN_ERROR = 'Client attempted registration on mobile.';
const CLIENT_INCOMPLETE_PROFILE_LOGIN_ERROR =
  'Client attempted logging in with incomplete profile on mobile.';
const CLIENT_LOGIN_ERROR_MESSAGE =
  'Registration on mobile is coming soon.\nPlease complete registration from a desktop device.';

class AuthenticationService {
  /*@ngInject*/
  constructor(
    $log,
    $state,
    API,
    CommonAuthenticationService,
    MessengerService,
    Search
  ) {
    this.$log = $log;
    this.$state = $state;
    this.API = API;
    this.CommonAuthenticationService = CommonAuthenticationService;
    this.MessengerService = MessengerService;
    this.Search = Search;
  }

  // Hide the fact that user is actually stored in CommonAuthenticationService
  get user() {
    return this.CommonAuthenticationService.user;
  }

  // Hide the fact that profile is actually stored in CommonAuthenticationService
  get profile() {
    return this.CommonAuthenticationService.profile;
  }

  getProfile() {
    return this.CommonAuthenticationService.getProfile();
  }

  canLogout() {
    return this.CommonAuthenticationService.canLogout();
  }

  cleanSessionData() {
    this.CommonAuthenticationService.cleanSessionData();
  }

  /**
   * .getAuthenticatedUser() must be called before checking any auth-related state (eg. .isMember())
   * to ensure that the AuthenticationService has up-to-date info.
   *
   * @returns A Promise that resolves with the logged in user.
   */
  getAuthenticatedUser() {
    return this.CommonAuthenticationService.getAuthenticatedUser();
  }

  getMyProfileId() {
    return this.profile.id;
  }

  hasChosenPassword() {
    return this.CommonAuthenticationService.hasChosenPassword();
  }

  hasSession() {
    return this.CommonAuthenticationService.hasSession();
  }

  hasUser() {
    return this.CommonAuthenticationService.hasUser();
  }

  isClient() {
    return this.CommonAuthenticationService.isClient();
  }

  isLimitedClient() {
    return this.CommonAuthenticationService.isLimitedClient();
  }

  isMessagingDisabled() {
    return this.CommonAuthenticationService.isMessagingDisabled();
  }

  isLoggedIn() {
    return this.CommonAuthenticationService.isLoggedIn();
  }

  isMember() {
    return this.CommonAuthenticationService.isMember();
  }

  isMemberPublic() {
    return this.CommonAuthenticationService.isMemberPublic();
  }

  isProfileComplete() {
    return this.CommonAuthenticationService.isProfileComplete();
  }

  login(email, password) {
    return this.CommonAuthenticationService.login(email, password, {
      onRetrieveProfileSuccess: this._bailIfClientWithoutPassword.bind(this)
    })
      .then(() => {
        let conversationState = this.isClient()
          ? states.CLIENTS_CONVERSATION
          : states.MEMBERS_CONVERSATION;
        let listState = this.isClient()
          ? states.CLIENTS_CHAT
          : states.MEMBERS_CHAT;
        this.MessengerService.init(this.user, {
          conversation: conversationState,
          list: listState,
          redirectListState: false
        });
      })
      .catch(this._handleLoginError.bind(this));
  }

  loginWithOneTimeToken($stateParams, nextState) {
    return this.CommonAuthenticationService.loginWithOneTimeToken(
      $stateParams,
      {
        onRetrieveUserSuccess: this._bailIfClientWithoutPassword.bind(this),
        onRetrieveProfileSuccess: this._bailIfIncompleteClient.bind(this)
      }
    ).catch(error => {
      this.$state.go(nextState);
      throw error; // Re-throw so that consumers can add their own error handling.
    });
  }

  logout() {
    return this.CommonAuthenticationService.logout()
      .then(() => this._resetServices())
      .then(
        () => this.$state.go(states.LOGIN),
        error => {
          this.API.handleError('Failed to logout, please try again.')(error);
          throw error;
        }
      );
  }

  navigateToDefaultState() {
    return this.CommonAuthenticationService.getAuthenticatedUser().then(
      this._doNavigation.bind(this)
    );
  }

  redirectToLogin(extraParams = {}) {
    this.CommonAuthenticationService.redirectWithNextLocationParam(
      states.LOGIN,
      extraParams
    );
  }

  refresh() {
    return this.CommonAuthenticationService.refresh();
  }

  _bailIfClientWithoutPassword(user) {
    if (user.type === USER_TYPES.CLIENT && !user.hasChosenPassword) {
      this.CommonAuthenticationService.cleanSessionData();
      throw new Error(CLIENT_TOKEN_LOGIN_ERROR);
    }
    return user;
  }

  _bailIfIncompleteClient(profile) {
    if (this.user.type === USER_TYPES.CLIENT && !profile.isComplete) {
      this.CommonAuthenticationService.cleanSessionData();
      throw new Error(CLIENT_INCOMPLETE_PROFILE_LOGIN_ERROR);
    }
    return profile;
  }

  _doNavigation() {
    // Anonymous users need to log in first.
    if (!this.hasSession()) {
      this.redirectToLogin();
      return;
    }

    if (!this.isClient() && !this.isMember()) {
      this.logout();
      return;
    }

    // Members without passwords need to create one before doing anything else.
    // Clients shouldn't use mobile password creation for the time being.
    if (this.isMember() && !this.hasChosenPassword()) {
      this.CommonAuthenticationService.redirectToState(
        states.PASSWORD_CREATION
      );
      return;
    }

    // Complete clients should go to Chat
    if (this.isClient() && this.isProfileComplete()) {
      this.CommonAuthenticationService.redirectToState(states.CLIENTS_CHAT);
      return;
    }

    // Complete Members should see their own profile.
    if (this.isMember() && this.isProfileComplete()) {
      this.CommonAuthenticationService.redirectToState(
        states.MEMBERS_OWN_PROFILE
      );
      return;
    }

    // Incomplete Members should complete registration first.
    if (this.isMember() && !this.isProfileComplete()) {
      this.CommonAuthenticationService.redirectToState(
        states.REGISTRATION_PROFILE
      );
      return;
    }

    this.CommonAuthenticationService.redirectToState(states.SERVER_ERROR);
    this.$log.error(
      'navigateToDefaultState could not find an appropriate redirect!'
    );
  }

  _handleLoginError(error) {
    if (error.message === CLIENT_INCOMPLETE_PROFILE_LOGIN_ERROR) {
      alert(CLIENT_LOGIN_ERROR_MESSAGE);
      this.navigateToDefaultState();
    } else {
      this.API.handleError()(error);
      throw error; // Allow caller of login() to attach custom error handler
    }
  }

  _handleTokenLoginError(error) {
    if (error.message === CLIENT_TOKEN_LOGIN_ERROR) {
      alert(CLIENT_LOGIN_ERROR_MESSAGE);
      this.$state.go(states.LOGIN);
    } else if (error.message === CLIENT_INCOMPLETE_PROFILE_LOGIN_ERROR) {
      alert(CLIENT_LOGIN_ERROR_MESSAGE);
      this.navigateToDefaultState();
    } else {
      this.$state.go(states.REQUEST_NEW_TOKEN);
    }

    throw error; // Allow caller of loginWithOneTimeToken() to attach custom error handler
  }

  _resetServices() {
    this.Search.search = '';
  }
}

export { CLIENT_TOKEN_LOGIN_ERROR };
export default angular
  .module('wc.auth.authService', [
    'ngStorage',
    apiModule.name,
    commonAuthenticationService.name,
    search.name
  ])
  .service('AuthenticationService', AuthenticationService);
