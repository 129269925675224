import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

const wcMobileMemberProfileOverview = {
  bindings: {
    member: '<'
  },
  templateUrl: templateUrl,
  transclude: true
};

export default angular
  .module('wcMobile.components.profile.member.overview', [])
  .component('wcmMemberProfileOverview', wcMobileMemberProfileOverview);
