import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wc.components.messenger.recipientInfo', [])
  .component('wcmMessengerRecipientInfo', {
    bindings: {
      recipient: '<'
    },
    templateUrl: templateUrl
  });
