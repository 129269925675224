import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

/**
 * This button can be used to transclude any content.
 * It includes basic styling to provide the app-wide look and feel via attribute directives:
 *
 * - `accent` for accent background.
 *
 */
export default angular
  .module('wcMobile.components.buttons.genericButton', [])
  .component('wcmButton', {
    bindings: {
      disabled: '<?wcmDisabled',
      onClick: '&?',
      fullWidth: '<?',
      type: '@?'
    },
    controller: /*@ngInject*/ function($attrs) {
      this.accent = 'accent' in $attrs;
    },
    templateUrl: templateUrl,
    transclude: true
  });
