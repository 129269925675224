import angular from 'angular';

import messengerSend from './messengerSend';
import inverseButton from './inversebutton';
import ReactInverseButton from '../../../desktop/components/react/buttons/InverseButton';

export default angular.module('wcCommon.components.buttons', [
  messengerSend.name,
  inverseButton.name,
  ReactInverseButton.name
]);
