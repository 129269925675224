import angular from 'angular';

import BasicsForm from './basicsForm';
import Complete from './complete';
import ExperienceForm from './experienceForm';
import ExtrasForm from './extrasForm';
import PageHeader from './pageHeader';
import RemunerationForm from './remunerationForm';
import templateUrl from './template.html';
import states from '../../services/States';
import './styles.scss';

class MobileRegistrationController {
  /*@ngInject*/
  constructor($q, $window, API, $state, $stateParams, AuthenticationService) {
    this.$q = $q;
    this.$window = $window;
    this.states = states;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.skipRemunerationPage = false;

    this.AuthenticationService = AuthenticationService;
    this.currentPage = this.$stateParams.currentPage;
    this.activePage = this.currentPage;
    this.API = API;
    this.loading = true;

    this.pages = [
      {
        estimatedTimeMins: 2,
        state: this.states.REGISTRATION_PROFILE,
        name: 'Complete Your Profile'
      },
      {
        estimatedTimeMins: 1,
        state: this.states.REGISTRATION_EXPERIENCE,
        name: 'Experience'
      },
      {
        estimatedTimeMins: 1,
        state: this.states.REGISTRATION_REMUNERATION,
        name: 'Compensation'
      },
      {
        estimatedTimeMins: 1,
        state: this.states.REGISTRATION_ABOUT,
        name: 'About You'
      }
    ];

    this.availablePages = this.pages;

    this.profilePromise = this.AuthenticationService.getProfile().then(
      profile => {
        // Remove the remuneration page, if needed
        if (profile.noCompensation) {
          this.skipRemunerationPage = true;

          this.availablePages = this.pages.filter(
            e => e.state !== this.states.REGISTRATION_REMUNERATION
          );
          this.updateActivePage();
        }
      }
    );
    let userPromise = API.User.get()
      .then(this.setUser.bind(this))
      .then(API.retrieveMyProfile)
      .then(this.setProfile.bind(this))
      .then(this.getAddress.bind(this))
      .then(API.Currencies.getList)
      .then(this.setCurrencies.bind(this));

    this.ethnicities = [];
    let ethnicityPromise = API.Ethnicities.getList().then(ethnicities => {
      this.ethnicities = ethnicities;
    });

    this.countries = [];
    this.countryPromise = API.Countries.getList().then(countries => {
      this.countries = countries;
    });

    $q.all([userPromise, ethnicityPromise, this.countryPromise]).then(() => {
      if (this.address) {
        this.address.country = this.countries.filter(
          country => country.name === this.address.countryName
        )[0];
      }
      this.loading = false;
    });
  }

  getAddress(profile) {
    if (!profile || !profile.address) {
      this.address = {};
      return this.$q(function(resolve) {
        resolve();
      });
    }
    return this.API.restangularizeUrl(profile.address)
      .get()
      .then(address => {
        this.address = address;
      });
  }

  findRemunerationPageIndex() {
    let remunerationPage = this.pages.find(
      e => e.state === this.states.REGISTRATION_REMUNERATION
    );
    return this.pages.indexOf(remunerationPage);
  }

  updateActivePage() {
    this.activePage = this.availablePages.indexOf(this.pages[this.currentPage]);
  }

  setProfile(profile) {
    this.profile = profile;
    if (!this.profile || !this.profile.currentPosition) {
      this.position = {
        positionType: null,
        city: null,
        company: null,
        current: null,
        endDate: null,
        startDate: null,
        internalJobTitle: null
      };
    } else {
      this.position = {
        positionType:
          this.profile.currentPosition.positionType &&
          this.profile.currentPosition.positionType.code
            ? this.profile.currentPosition.positionType.code
            : null,
        city: this.profile.currentPosition.city
          ? this.profile.currentPosition.city
          : null,
        company: this.profile.currentPosition.company
          ? this.profile.currentPosition.company
          : null,
        current:
          typeof this.profile.currentPosition.current === 'boolean'
            ? this.profile.currentPosition.current
            : null,
        endDate: this.profile.currentPosition.endDate
          ? this.profile.currentPosition.endDate
          : null,
        startDate: this.profile.currentPosition.startDate
          ? new Date(this.profile.currentPosition.startDate)
          : null,
        internalJobTitle: this.profile.currentPosition.internalJobTitle
          ? this.profile.currentPosition.internalJobTitle
          : null
      };
    }

    this.remuneration =
      this.profile && this.profile.remuneration
        ? this.profile.remuneration
        : {};

    return this.profile;
  }

  setUser(user) {
    this.user = user;
  }

  setCurrencies(currencies) {
    this.currencies = currencies;

    if (!this.profile) {
      return;
    }

    this.profile.currency = this.currencies.find(currency => {
      return currency.code === this.profile.capexCurrency;
    });
    if (this.remuneration.salaryCurrency) {
      this.remuneration.currency = this.currencies.find(currency => {
        return currency.code === this.remuneration.salaryCurrency;
      });
    } else {
      this.remuneration.currency = this.profile.currency;
    }
  }

  nextPage() {
    let nPage = this.currentPage + 1;
    if (this.skipRemunerationPage) {
      if (nPage === this.findRemunerationPageIndex()) {
        nPage += 1;
      }
    }
    this.$state.go(this.pages[nPage].state, {}, { reload: true });
  }

  previousPage() {
    let pPage = this.currentPage - 1;
    if (this.skipRemunerationPage) {
      if (pPage === this.findRemunerationPageIndex()) {
        pPage -= 1;
      }
    }
    this.$state.go(this.pages[pPage].state, {}, { reload: true });
  }
}

export default angular
  .module('wcMobile.components.registration', [
    BasicsForm.name,
    Complete.name,
    ExperienceForm.name,
    ExtrasForm.name,
    PageHeader.name,
    RemunerationForm.name
  ])
  .component('wcmRegistration', {
    templateUrl: templateUrl,
    controller: MobileRegistrationController
  });
