import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

const REMIND_SUCCESS =
  "We have sent the password reset instructions to the email address you provided. If you haven't received the email, please contact support.";
const REMIND_ERROR =
  'There was a problem sending the message. Please contact support.';

class ForgottenPasswordController {
  /*@ngInject*/
  constructor(API) {
    this.API = API;
  }

  onSubmit() {
    if (this.form.$valid) {
      this.API.PasswordRecovery.post({
        email: this.email
      }).then(() => alert(REMIND_SUCCESS), () => alert(REMIND_ERROR));
    }
  }
}

export default angular
  .module('wcMobile.components.authentication.forgottenPassword', [])
  .component('wcmForgottenPassword', {
    controller: ForgottenPasswordController,
    templateUrl: templateUrl
  });
