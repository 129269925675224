import React from 'react';
import PropTypes from 'prop-types';

import { QAndA } from '../../../q-and-a/QAndA';
import { ImageBubbleList } from '../../../image-bubble-list/ImageBubbleList';

import { WidthExpander } from '../../../../../../desktop/components/react/width-expander/WidthExpander';

export const MemberProfileInsights = ({ member }) => {
  return (
    <React.Fragment>
      {member.answers && member.answers.length && (
        <article className="Separator">
          <div>
            <h2 className="WcMemberProfileContent__Subheading">
              Questions &amp; Answers
            </h2>

            <div>
              {member.answers.map((answer, index) =>
                answer.text && answer.text.length ? (
                  <QAndA
                    key={answer.id}
                    question={answer.questionText}
                    answer={answer.text}
                    separator={index !== 0}
                  />
                ) : (
                  <React.Fragment key={index} />
                )
              )}
            </div>
          </div>
        </article>
      )}

      {member.hobbies && member.hobbies.length && (
        <article className="Separator">
          <div>
            <h2 className="WcMemberProfileContent__Subheading">
              Hobbies &amp; Interests
            </h2>
            <ImageBubbleList
              data={member.hobbies}
              sourceField="image"
              captionField="name"
            />
          </div>
        </article>
      )}

      <WidthExpander />
    </React.Fragment>
  );
};

MemberProfileInsights.propTypes = {
  member: PropTypes.object
};
