import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

import ownMemberProfileActions from './own-member-profile-actions';
import clientMemberProfileActions from './client-member-profile-actions';
import otherMemberProfileActions from './other-member-profile-actions';

export default angular
  .module('wcMobile.components.profile.profileActions', [
    ownMemberProfileActions.name,
    clientMemberProfileActions.name,
    otherMemberProfileActions.name
  ])
  .component('wcmProfileActions', {
    bindings: {
      member: '<',
      isOwn: '=?',
      isClient: '<?'
    },
    templateUrl: templateUrl
  });
