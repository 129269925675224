import angular from 'angular';

import states from '../../../services/States';
import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wcMobile.components.memberNavbar', [])
  .component('wcmMemberNavbar', {
    /*@ngInject*/
    controller: function MemberNavbarController(AuthenticationService) {
      this.states = states;
      this.AuthenticationService = AuthenticationService;
      this.memberIsPublic = AuthenticationService.isMemberPublic();
    },
    templateUrl: templateUrl
  });
