import angular from 'angular';
import { parse, format, isValidNumber } from 'libphonenumber-js';

import templateUrl from './template.html';

class PhoneNumberInputController {
  constructor() {
    this._model = this.model;
    this.format();
  }

  format() {
    try {
      const parsedNumber = parse(this._model);
      if (isValidNumber(parsedNumber)) {
        this._model = format(parsedNumber, 'International');
        this.model = this._model.replace(/\s+/g, '');
      }
    } catch (error) {
      // wc-valid-phone-number registers a validator named `phoneNumber` with Angular. This is
      // a copy-paste from the desktop phone number input (not directly reusable unfortunately). I'm
      // not sure why this directive forces another validator to pass, seems like bad coupling to
      // me.
      this.ngModelController.$setValidity('phoneNumber', true);
    }
  }
}

export default angular
  .module('wcMobile.components.forms.phoneNumberInput', [])
  .component('wcmPhoneNumberInput', {
    bindings: {
      model: '=ngModel',
      name: '@'
    },
    controller: PhoneNumberInputController,
    templateUrl: templateUrl,
    require: {
      ngModelController: 'ngModel'
    }
  });
