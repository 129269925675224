import angular from 'angular';

import { ImageDataConverter } from '../../../../common/services/ImageUtil';
import states from '../../../services/States';
import './styles.scss';
import templateUrl from './template.html';
import Textarea from '../../../../desktop/components/inputs/textarea';

class ExtrasFormController {
  /*@ngInject*/
  constructor(
    $q,
    $scope,
    $state,
    API,
    AuthenticationService,
    CropperService,
    MessengerService
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;
    this.CropperService = CropperService;
    this.MessengerService = MessengerService;
    this.showCompensation = this.profile && !this.profile.noCompensation;
    if (this.showCompensation) {
      this.nextState = states.MEMBERS_SETTINGS;
    } else {
      this.nextState = states.MEMBERS_OWN_PROFILE;
    }

    this.currentProfilePicture = this.profile
      ? this.profile.profilePicture
      : null;
    this.cropMode = false;

    this.profilePictureCaptions = {
      PROCESSING: 'Processing...',
      UPLOADING: 'Uploading...',
      UPLOADED: 'Change profile picture'
    };
  }

  onCancelCrop() {
    this.imageData = null;
    this.deferredCroppedImage.reject();
    this.cropMode = false;
  }

  onFinishCrop() {
    const croppedImage = new ImageDataConverter(
      this.CropperService.getCroppedJpeg()
    ).dataURItoBlob();
    this.deferredCroppedImage.resolve(croppedImage);
    this.cropMode = false;
  }

  onProcessImage(imageFromFileUploadComponent) {
    this.deferredCroppedImage = this.$q.defer();
    const fileReader = new FileReader();

    fileReader.onload = fileLoadedEvent => {
      this.imageData = fileLoadedEvent.target.result;
      this.cropMode = true;
      this.$scope.$digest();
      this.CropperService.init('#cropper');
    };

    fileReader.readAsDataURL(imageFromFileUploadComponent);

    return this.deferredCroppedImage.promise;
  }

  onUploaded() {
    if (this.profile) {
      this.profile.profilePicture = this.profilePictureUrl;
    }
  }

  submit() {
    if (!this.form.$valid || !this.profile) {
      return;
    }

    this.pendingSubmit = true;

    const updatedProfileData = {
      openToRelocate: this.profile.openToRelocate,
      aspirationalComp: this.profile.aspirationalComp,
      interestedInNonExec: this.profile.interestedInNonExec
    };

    this.API.restangularize(this.profile)
      .patch(updatedProfileData)
      .then(() =>
        this.API.restangularize(this.profile)
          .all('complete')
          .post()
      )
      // Refresh the AuthenticationService, because isComplete has changed now.
      .then(() => this.AuthenticationService.refresh())
      .then(user => {
        let conversationState = this.AuthenticationService.isClient()
          ? states.CLIENTS_CONVERSATION
          : states.MEMBERS_CONVERSATION;
        let listState = this.AuthenticationService.isClient()
          ? states.CLIENTS_CHAT
          : states.MEMBERS_CHAT;
        this.MessengerService.init(user, {
          conversation: conversationState,
          list: listState,
          redirectListState: false
        });
      })
      .then(() => this.$state.go(this.nextState), this.API.handleError())
      .then(() => {
        // This is guaranteed to be executed since we handled any errors in the last step.
        this.pendingSubmit = false;
      });
  }
}

export default angular
  .module('wcMobile.components.registration.extrasForm', [Textarea.name])
  .component('wcmRegistrationExtrasForm', {
    bindings: {
      onPrevious: '&',
      profile: '='
    },
    controller: ExtrasFormController,
    templateUrl: templateUrl
  });
