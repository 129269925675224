import angular from 'angular';

import templateUrl from './template.html';

class RemunerationFormController {
  /*@ngInject*/
  constructor($window, API, CurrencyService, AuthenticationService) {
    this.$window = $window;
    this.API = API;
    this.formatCurrency = CurrencyService.formatCurrency;
    this.noCompensation = true;
    this.AuthenticationService = AuthenticationService;
    this.profilePromise = this.AuthenticationService.getProfile().then(
      profile => {
        if (!profile || profile.noCompensation) {
          this.onSubmit();
        }
      }
    );
  }

  submit() {
    if (!this.form.$valid) {
      return;
    }

    this.pendingSubmit = true;

    function updateRemuneration() {
      const remunerationData = {
        bonusAmount: this.remuneration.bonusAmount,
        bonusCurrency: this.remuneration.currency.code,
        buyoutAmount: this.remuneration.buyoutAmount,
        buyoutCurrency: this.remuneration.currency.code,
        longTermCashAmount: this.remuneration.longTermCashAmount,
        longTermCashCurrency: this.remuneration.currency.code,
        otherFinancialAmount: this.remuneration.otherFinancialAmount
          ? this.remuneration.otherFinancialAmount
          : null,
        otherFinancialCurrency: this.remuneration.currency.code,
        stockAndOptionsAmount: this.remuneration.stockAndOptionsAmount,
        stockAndOptionsCurrency: this.remuneration.currency.code,
        salaryAmount: this.remuneration.salaryAmount,
        salaryCurrency: this.remuneration.currency.code
      };

      if (this.profile && this.profile.remuneration) {
        return this.API.restangularizeUrl(this.profile.remuneration.url).patch(
          remunerationData
        );
      } else {
        return this.API.Remuneration.post(remunerationData);
      }
    }

    updateRemuneration
      .bind(this)()
      .then(
        remuneration => {
          if (this.profile) {
            this.profile.remuneration = remuneration;
          }
          this.onSubmit();
          this.pendingSubmit = false;
        },
        () => {
          this.$window.alert(
            'Failed to submit remuneration. Please try again.'
          );
          this.pendingSubmit = false;
        }
      );
  }
}

export default angular
  .module('wcMobile.components.registration.remunerationForm', [])
  .component('wcmRegistrationRemunerationForm', {
    bindings: {
      currencies: '<',
      onPrevious: '&',
      onSubmit: '&',
      profile: '=',
      remuneration: '='
    },
    controller: RemunerationFormController,
    templateUrl: templateUrl
  });
