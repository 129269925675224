import angular from 'angular';

import ENV_CONFIG from './EnvConfig.constant';
import GoogleMapsConfig from '../../common/config/GoogleMaps.config';
import AuthenticationConfig from './Authentication.constant';

import commonConfig from '../../common/services/config';
import ThemeConfig from '../../common/services/config/Theme.config';
import GoogleAnalyticsConfig from '../../common/config/GoogleAnalytics.config';
import store from '../../common/redux/store';

export default angular
  .module('wcMobile.config', [commonConfig.name])
  .config(
    /*@ngInject*/ $ngReduxProvider => {
      $ngReduxProvider.provideStore(store);
    }
  )
  .config(
    /*@ngInject*/ function AppConfig(EnvConfigProvider) {
      EnvConfigProvider.setEnvConfig(ENV_CONFIG);
    }
  )
  .config(GoogleAnalyticsConfig)
  .constant('AuthenticationConfig', AuthenticationConfig)
  .constant(GoogleMapsConfig.NAME, GoogleMapsConfig)
  .config(ThemeConfig);
