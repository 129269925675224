import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wcMobile.components.authentication.forgottenHelpText', [])
  .component('wcmForgottenHelpText', {
    templateUrl: templateUrl,
    transclude: true
  });
