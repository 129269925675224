/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import theme from '../../../../../../common/styles/theme';
import './styles.scss';
import {
  PersonSharp as BasePersonSharp,
  Add as BaseAdd,
  Clear as BaseClear,
  Description as FileText,
  Email as BaseEmail
} from '@material-ui/icons';

const Button = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef(({ withMargin, withBorder, withPadding, ...props }, ref) => (
    <ButtonBase classes={{ root: 'root' }} {...props} ref={ref} />
  ))
)`
  &.root {
    text-transform: uppercase
    background: transparent;
    border: ${props =>
      props.withBorder
        ? `1px solid ${props.theme.palette.secondary[500]}`
        : 'none'};
    color: ${props => props.theme.palette.secondary[500]};
    border-radius: 2px;
    font-weight: 700;
    font-size: 14px;
    height: 42px;
    margin: ${props => (props.withMargin ? '12px 0' : '0')};
    padding: ${props => (props.withPadding ? '0 24px !important; ' : '0')};
    transition: background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover,
    &:focus {
      background-color: ${props => props.theme.palette.extraColors.lightGrey};
      border-color: ${props => props.theme.palette.secondary[700]};
    }
  }
`;

const iconStyles = css`
  margin-right: 12px;
  font-size: 20px;
  position: relative;
  top: -1px;
`;

const getStyledIcon = Component => {
  return styled(({ ...props }) => (
    <Component classes={{ root: 'root' }} {...props} />
  ))`
    &.root {
      ${iconStyles}
    }
  `;
};

const PersonIcon = getStyledIcon(BasePersonSharp);
const AddIcon = getStyledIcon(BaseAdd);
const ClearIcon = getStyledIcon(BaseClear);
const FileIcon = getStyledIcon(FileText);
const EmailIcon = getStyledIcon(BaseEmail);

const icons = {
  PersonIcon: <PersonIcon />,
  AddIcon: <AddIcon />,
  ClearIcon: <ClearIcon />,
  FileText: <FileIcon />,
  EmailIcon: <EmailIcon />
};

const InverseButton = ({
  type,
  onClick,
  icon = null,
  children,
  className,
  pullFocus,
  disabled,
  label,
  withBorder = true,
  withMargin = false,
  withPadding = true
}) => {
  const button = useRef(null);
  useEffect(() => {
    if (!pullFocus) {
      return;
    }
    button.current.focus();
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        type={type || 'button'}
        onClick={onClick}
        ref={button}
        disabled={disabled}
        className={className}
        withBorder={withBorder}
        withMargin={withMargin}
        withPadding={withPadding}
      >
        {icon && icons[icon]}
        {children && children}
        {label && label}
      </Button>
    </ThemeProvider>
  );
};

InverseButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  pullFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  withBorder: PropTypes.bool,
  label: PropTypes.string,
  withMargin: PropTypes.bool,
  withPadding: PropTypes.bool
};

export default InverseButton;
