const states = {
  AUTHENTICATION: 'authentication',
  CHANGE_PASSWORD: 'changePassword',
  FORGOTTEN_PASSWORD: 'forgottenPassword',
  LOGIN: 'authentication.login',
  CLIENTS: 'clients',
  CLIENTS_PROFILE: 'clients.profile',
  CLIENTS_CHAT: 'clients.messages',
  CLIENTS_CONVERSATION: 'clients.conversation',
  CLIENT_DOCUMENT_DOWNLOAD: 'clients.documentsDownload',
  CLIENTS_SEARCH: 'clients.search',
  CLIENTS_SETTINGS: 'clients.settings',
  MEMBERS: 'members',
  MEMBERS_OWN_PROFILE: 'members.myProfile',
  MEMBERS_PROFILE: 'members.profile',
  MEMBERS_CHAT: 'members.messages',
  MEMBERS_CONVERSATION: 'members.conversation',
  MEMBERS_REGISTRATION_COMPLETE: 'members.registrationComplete',
  MEMBERS_SEARCH: 'members.search',
  MEMBERS_SETTINGS: 'members.settings',
  NOT_FOUND: 'notFound',
  PASSWORD_CREATION: 'passwordCreation',
  REGISTRATION: 'registration',
  REGISTRATION_PROFILE: 'registration.profile',
  REGISTRATION_EXPERIENCE: 'registration.experience',
  REGISTRATION_REMUNERATION: 'registration.remuneration',
  REGISTRATION_ABOUT: 'registration.aboutYou',
  REQUEST_NEW_TOKEN: 'requestNewToken',
  ROOT: 'root',
  SERVER_ERROR: 'serverError',
  TOKEN_LEGACY: 'token',
  TOKEN_REDIRECT: 'tokenRedirect',
  TOKEN_LOGIN: 'tokenLogin',
  TOKEN_REGISTER: 'tokenRegister'
};

export default states;
