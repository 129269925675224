import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CircularImage.scss';
import { MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64 } from '../../../../services/ImageUrls';

export const CircularImage = ({
  alt = '',
  src = '',
  missingImageUrl = '',
  className = ''
}) => {
  const modern = typeof Modernizr !== 'undefined' ? Modernizr.objectfit : true;
  const missingImage =
    missingImageUrl || MISSING_PROFILE_PICTURE_WITH_ACCENT_BACKGROUND_BASE64;

  const [imgSrc, setImgSrc] = useState(src);
  const onError = () => setImgSrc(missingImage);

  return modern ? (
    <img
      className={`${className} CircularImage CircularImage--modern`}
      src={imgSrc ? imgSrc : missingImage}
      title={alt}
      alt={alt}
      onError={onError}
    />
  ) : (
    <div
      className={`${className} CircularImage CircularImage--legacy`}
      style={{ backgroundImage: `url(${src || missingImage})` }}
    />
  );
};

CircularImage.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  missingImageUrl: PropTypes.string
};
