import angular from 'angular';

import profileOverview from './profile-overview';
import profileActions from './profile-actions';
import profileContent from './profile-content';

import templateUrl from './template.html';
import './styles.scss';

class ProfileController {
  /*@ngInject*/
  constructor($stateParams, AuthenticationService) {
    this.AuthenticationService = AuthenticationService;

    this.otherProfileId = $stateParams.id;
    this.checkUserType();
  }

  checkUserType() {
    return this.AuthenticationService.getAuthenticatedUser().then(() => {
      if (!this.AuthenticationService.hasUser()) {
        return;
      }
      if (!this.isOwn) {
        if (
          this._isMyProfile(
            this.AuthenticationService.getMyProfileId(),
            this.otherProfileId
          )
        ) {
          this.isOwn = true;
        }
        if (this.AuthenticationService.isClient()) {
          this.isClient = true;
        }
      }
      this.userTypeSet = true;
    });
  }

  _isMyProfile(authenticatedProfileId, otherProfileId) {
    return (
      this.AuthenticationService.isMember() &&
      Number.parseInt(otherProfileId) === authenticatedProfileId
    );
  }

  setMember(profile) {
    this.loaded = true;
    this.member = profile;
  }

  setUser(user) {
    this.user = user;
  }
}

export default angular
  .module('wcMobile.components.profile', [
    profileOverview.name,
    profileActions.name,
    profileContent.name
  ])
  .component('wcmProfile', {
    bindings: {
      isOwn: '=?'
    },
    controller: ProfileController,
    templateUrl: templateUrl
  });
