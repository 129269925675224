import './ConversationSummary.scss';
import controller from './ConversationSummary.controller';
import templateUrl from './ConversationSummary.html';

export default {
  NAME: 'wcMessengerConversationSummary',
  bindings: {
    conversation: '<'
  },
  controller: controller,
  templateUrl: templateUrl
};
