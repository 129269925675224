import angular from 'angular';

import templateUrl from './template.html';
import './styles.scss';

class BasicsFormController {
  /*@ngInject*/
  constructor(API, PlaceSearchService, GoogleMapService, $element, $window) {
    this.API = API;
    this.PlaceSearchService = PlaceSearchService;
    this.GoogleMapService = GoogleMapService;
    this.$element = $element;
    this.$window = $window;

    // Set suggestions to null by default, to tell if the city is changed
    this.suggestions = null;
    this.notFound = false;
    this.showHint = false;

    this.citySearchTerm = this.address ? this.address.city : '';
  }

  $onInit() {
    // Mock an input element which is required by Google Places Service
    let mockInput = angular.element('<place-input/>')[0];
    this.GoogleMapService.get().then(gmaps => {
      this.service = new gmaps.maps.places.PlacesService(mockInput);
    });
  }

  submit() {
    this._validateCityInput();
    this.validateEthnicity();
    if (!this.form.$valid) {
      return;
    }

    this.pendingSubmit = true;

    const patchUser = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      mobileNumber: this.user.mobileNumber,
      contactSharing: this.user.contactSharing
    };

    const patchProfile = {
      ethnicity: this.profile.ethnicity ? this.profile.ethnicity.id : null,
      ethnicityConsent: this.profile.ethnicityConsent,
      gender: this.profile.gender
    };

    function updateAddress() {
      const addressData = {
        city: this.address.city,
        country: this.address.country.id,
        state: this.address.state
      };

      if (this.suggestions !== null) {
        // API currently not returning coords for existing addresses -> do not try to update to empty if not changed
        addressData.coords = this.address.coords;
      }

      if (this.profile.address) {
        return this.API.restangularizeUrl(this.profile.address).patch(
          addressData
        );
      } else {
        return this.API.Address.post(addressData).then(address => {
          // Store the URL of the created Address to prevent further POST attempts (#2443)
          this.profile.address = address.url;
        });
      }
    }

    this.API.User.patch(patchUser)
      .then(() => this.API.restangularize(this.profile).patch(patchProfile))
      .then(updateAddress.bind(this))
      .then(
        () => {
          this.onSubmit();
          this.pendingSubmit = false;
        },
        () => {
          this.$window.alert(
            'Failed to submit basic information. Please try again.'
          );
          this.pendingSubmit = false;
        }
      );
  }

  onBlur() {
    this.showHint = false;
    this._validateCityInput();
  }

  onFocus() {
    this.showHint = true;
    this._resetCityInputValidation();
  }

  searchCity(searchTerm) {
    this.address.coords = null;
    if (searchTerm) {
      this.PlaceSearchService.autocomplete(searchTerm)
        .then(resp => {
          this.notFound = false;
          this.suggestions = resp;
        })
        .catch(() => {
          this.notFound = true;
          this.suggestions = [];
        });
    }
  }

  selectCity(city) {
    this._setCity(city);
    this.suggestions = [];
  }

  _setCity(city) {
    this.citySearchTerm = this.address.city =
      city.structured_formatting.main_text;
    this._getDetails(city).then(details => {
      this._setDetails(details);
      this._validateCityInput();
    });
  }

  _getDetails(city) {
    return this.PlaceSearchService.getDetailsWithService(
      this.service,
      city.place_id
    );
  }

  _setDetails(details) {
    this.address.coords = {
      lat: details.geometry.location.lat(),
      lng: details.geometry.location.lng()
    };
    let countryComponent = details.address_components.find(
      component => component.types.indexOf('country') > -1
    );
    this.address.country = this.countries.find(
      country => country.name === countryComponent.long_name
    );
    let stateComponent = details.address_components.find(
      component => component.types.indexOf('administrative_area_level_1') > -1
    );
    this.address.state = stateComponent ? stateComponent.long_name : '';
  }

  _validateCityInput() {
    if (!this.address || (!this.address.coords && this.suggestions !== null)) {
      this.form.city.$setValidity('selectFromOptions', false);
    } else {
      this.form.city.$setValidity('selectFromOptions', true);
    }
  }

  _resetCityInputValidation() {
    this.form.city.$setValidity('selectFromOptions', true);
  }

  validateEthnicity() {
    if (
      this.profile.ethnicityConsent &&
      !(this.profile.ethnicity && this.profile.ethnicity.id)
    ) {
      this.form.ethnicity.$setValidity('required', false);
    }
  }
}

export default angular
  .module('wcMobile.components.registration.basicsForm', [])
  .component('wcmRegistrationBasicsForm', {
    bindings: {
      address: '=',
      countries: '<',
      ethnicities: '<',
      onSubmit: '&',
      profile: '=',
      user: '='
    },
    controller: BasicsFormController,
    templateUrl: templateUrl
  });
