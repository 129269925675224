import moment from 'moment';

export default class MessageBubbleController {
  /*@ngInject*/
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.setAvatar(this.message);
    this.sentAtFormatted = moment(this.sentAt).calendar(null, {
      sameDay: 'LT',
      lastDay: '[Yesterday] LT',
      lastWeek: '[Last] dddd LT',
      sameElse: 'DD/MM/YYYY'
    });
  }

  async setAvatar(message) {
    const author = await this.getMessageAuthor(message);
    this.avatarSrc = author.attributes.avatar_url;
    this.$scope.$apply();
  }

  async getMessageAuthor(message) {
    const member = await message.getMember();
    return await member.getUser();
  }
}
