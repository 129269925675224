import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class DropdownSelectController {
  /*@ngInject*/
  constructor($log, $element) {
    this.$log = $log;

    this.required = $element[0].hasAttribute('required');

    if (!this.labelField) {
      this.labelField = 'name';
    }

    $element.find('md-select').on('blur', () => {
      this.touched = true;
    });

    this.getOptions();
  }

  getOptions() {
    this.endpoint
      .getList()
      .then(this.handleSuccess.bind(this), this.handleError.bind(this));
  }

  handleSuccess(options) {
    this.options = options;
    if (options.length === 0) {
      this.$log.warn(`Empty list returned`);
    }
  }

  handleError(error) {
    if (
      error &&
      error.status &&
      error.statusText &&
      error.data &&
      error.data.detail &&
      error.config &&
      error.config.url
    ) {
      this.$log.error(
        `API error: ${error.data.detail} (${error.config.url} ${error.status} ${
          error.statusText
        })`
      );
    } else if (error && error.status && error.statusText) {
      this.$log.error(`API error: ${error.status} ${error.statusText}`);
    } else {
      this.$log.error(`Unexpected API error ${error}`, error);
    }
  }
}

let DropdownSelect = {
  bindings: {
    endpoint: '<',
    fieldName: '@',
    form: '<?',
    hint: '@?',
    labelField: '@?',
    labelFunction: '&?',
    ngModel: '=',
    title: '@',
    onUpdate: '&?'
  },
  templateUrl: templateUrl,
  controller: DropdownSelectController
};

export default angular
  .module('wcMobile.components.inputs.selects.dropdownSelect', [])
  .component('dropdownSelect', DropdownSelect);
