/* Linear progress/loading bar with md-progress-linear component
 *  Usage: Bind loading and/or progress property
 *  If any of them true, the loader bar shown
 *  If progress is binded, it works in "determinate" mode, otherwise "indeterminate"
 */

import angular from 'angular';

import templateUrl from './template.html';

export default angular
  .module('wcMobile.components.loader', [])
  .component('wcmProgressLoader', {
    bindings: {
      loading: '<?',
      progress: '&?'
    },
    templateUrl: templateUrl
  });
