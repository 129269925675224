import templateUrl from './template.html';
import './styles.scss';

export default angular
  .module('wcMobile.components.memberSummary', [])
  .component('wcmMemberSummary', {
    bindings: {
      name: '@',
      profilePicture: '@',
      city: '@',
      country: '@',
      title: '@',
      company: '@'
    },
    templateUrl: templateUrl
  });
