import angular from 'angular';

import authentication from './authentication';
import buttons from './buttons';
import cityTypeahead from './inputs/selects/cityTypeahead';
import common from '../../common/components';
import cookieBar from './cookiebar';
import clientDocumentDownload from './client-document-download';
import dropdownSelect from './inputs/selects/dropdownselect';
import forms from './forms';
import loader from './loader';
import memberSummary from './memberSummary';
import messenger from './messenger';
import navbar from './navbar';
import profile from './profile';
import registration from './registration';
import search from './search';
import settings from './settings';
import scrollableBody from './scrollableBody';

export default angular.module('wcMobile.components', [
  authentication.name,
  buttons.name,
  cityTypeahead.name,
  common.name,
  cookieBar.name,
  clientDocumentDownload.name,
  dropdownSelect.name,
  forms.name,
  loader.name,
  memberSummary.name,
  messenger.name,
  navbar.name,
  profile.name,
  registration.name,
  settings.name,
  search.name,
  scrollableBody.name
]);
