import angular from 'angular';

import autoScrollContainer from './autoscrollContainer';
import buttons from './buttons';
import chips from './chips';
import circularImage from './circularimage/CircularImage.module';
import clamp from './clamp';
import cookieBar from './cookiebar';
import errorViews from './errorViews';
import expandableTextarea from './expandableTextarea';
import highlightedImageBubble from './highlightedImageBubble';
import icon from './icon';
import inputs from './inputs';
import logo from './logo';
import logomh from './logo-mh';
import memberProfile from './member-profile/index';
import messenger from './messenger/Messenger.module';
import missingImage from './missing-image';
import momentDurationFormatWrapper from './moment-duration-format-wrapper';
import navbar from './navbar';
import overviewCard from './overviewcard';
import passwordStrengthMeter from './passwordstrengthmeter';
import places from './places/Places.module';
import tooltip from './tooltip/Tooltip.module';
import validators from './validators';
import ReactEducationOverview from './react/education-overview';
import ReactContactDetails from './react/member-profile/contact-details';
import ReactBooleanChip from './react/chips';
import ReactStatsWidget from './react/stats-widget';
import ReactRemunerationGraph from './react/remuneration-graph';
import ReactCompensationHidden from './react/compensation-hidden';
import ReactRemunerationOverview from './react/remuneration-overview';
import ReactPositionOverview from './react/position/position-overview';
import reactMinimalPositionOverview from './react/position/minimal-position-overview';
import reactCompanyLogo from './react/company-logo';
import ReactNonExecPositionOverview from './react/position/non-exec-position-overview';
import ReactCircularImage from './react/circular-image';
import ReactImageBubble from './react/image-bubble';
import ReactImageBubbleList from './react/image-bubble-list';
import ReactQAndA from './react/q-and-a';
import ReactProfessionalSummary from './react/professional-summary';
import ReactClampedText from './react/clamped-text';
import reactExpandableTextarea from './react/expandable-textarea';
import reactCircularImage from './react/circular-image';

// React component imports without Angular2React wrapper
import { InputError, InputHint } from './react/inputs';
import ReactCookieBar from './react/cookie-bar';

export default angular.module('wcCommon.components', [
  autoScrollContainer.name,
  buttons.name,
  chips.name,
  circularImage.name,
  clamp.name,
  cookieBar.name,
  errorViews.name,
  expandableTextarea.name,
  highlightedImageBubble.name,
  icon.name,
  inputs.name,
  logo.name,
  logomh.name,
  memberProfile.name,
  messenger.name,
  missingImage.name,
  momentDurationFormatWrapper.name,
  navbar.name,
  overviewCard.name,
  passwordStrengthMeter.name,
  places.name,
  tooltip.name,
  validators.name,
  // react components
  reactMinimalPositionOverview.name,
  reactCompanyLogo.name,
  ReactCookieBar.name,
  ReactEducationOverview.name,
  ReactContactDetails.name,
  ReactBooleanChip.name,
  ReactStatsWidget.name,
  ReactRemunerationGraph.name,
  ReactCompensationHidden.name,
  ReactRemunerationOverview.name,
  ReactPositionOverview.name,
  ReactNonExecPositionOverview.name,
  ReactCircularImage.name,
  ReactImageBubble.name,
  ReactImageBubbleList.name,
  ReactQAndA.name,
  ReactProfessionalSummary.name,
  ReactClampedText.name,
  reactExpandableTextarea.name,
  reactCircularImage.name
]);

// React component exports
export { InputError, InputHint };
