import angular from 'angular';

import templateUrl from './template.html';

class OtherMemberProfileActionsController {
  /*@ngInject*/
  constructor(MessengerService) {
    this.MessengerService = MessengerService;
  }
}

export default angular
  .module('wcMobile.components.profile.profileActions.other', [])
  .component('wcmOtherMemberProfileActions', {
    bindings: {
      member: '<'
    },
    controller: OtherMemberProfileActionsController,
    templateUrl: templateUrl
  });
