import angular from 'angular';
import uiRouter from 'angular-ui-router';
require('imports-loader?angular=angular!angular-feature-flags');

import 'angular-elastic';

import ChatService from './Chat.service';
import MessengerContacts from './contacts/MessengerContacts.module';
import MessengerService from './Messenger.service';
import StatusIndicator from './statusIndicator/StatusIndicator.component';
import Toolbar from './toolbar/Toolbar.component';
import MessageBubblePartner from './messageBubble/messageBubblePartner';
import MessageBubbleUser from './messageBubble/messageBubbleUser';

import ConversationDetail from './conversationDetail/ConversationDetail.component';
import NewMessageForm from './newMessageForm/NewMessageForm.component';

export default angular
  .module('wc.messenger', [
    uiRouter,
    'monospaced.elastic',
    'feature-flags',
    MessageBubblePartner.name,
    MessageBubbleUser.name,
    MessengerContacts.name
  ])
  .component(ConversationDetail.NAME, ConversationDetail)
  .component(NewMessageForm.NAME, NewMessageForm)
  .component(StatusIndicator.NAME, StatusIndicator)
  .component(Toolbar.NAME, Toolbar)
  .service(ChatService.NAME, ChatService)
  .service(MessengerService.NAME, MessengerService);
