import MessengerContacts from './MessengerContacts.component';
import Toolbar from './toolbar/ContactsToolbar.component';
import ConversationList from './conversations/list/ConversationList.component';
import ConversationSummary from './conversations/summary/ConversationSummary.component';

export default angular
  .module('wc.messenger.contacts', [])
  .component(MessengerContacts.NAME, MessengerContacts)
  .component(Toolbar.NAME, Toolbar)
  .component(ConversationList.NAME, ConversationList)
  .component(ConversationSummary.NAME, ConversationSummary);
