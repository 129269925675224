import angular from 'angular';

import states from '../../../services/States';
import templateUrl from './template.html';

class ChangePasswordController {
  /*@ngInject*/
  constructor($state, $stateParams, API, AuthenticationService) {
    this.$state = $state;
    this.API = API;
    this.AuthenticationService = AuthenticationService;

    if (!$stateParams.token) {
      $state.go(states.LOGIN);
      return;
    }

    this.AuthenticationService.loginWithOneTimeToken(
      $stateParams,
      states.FORGOTTEN_PASSWORD
    );
  }

  onSubmit() {
    if (this.form.$valid) {
      this.API.User.all('password')
        .customPUT({ password: this.password })

        // Refresh the AuthenticationService, because hasChosenPassword might have changed now.
        .then(() => this.AuthenticationService.refresh())

        .then(
          () => {
            this.$state.go(states.ROOT);
          },
          () => {
            alert('An error occurred when updating your password.');
            this.$state.go(states.LOGIN);
          }
        );
    }
  }
}

export default angular
  .module('wcMobile.components.authentication.changePassword', [])
  .component('wcmChangePassword', {
    controller: ChangePasswordController,
    templateUrl: templateUrl
  });
