import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ClampedText } from '../../../clamped-text/ClampedText';

export const MemberProfileCandidateSummary = ({ summary }) => {
  return summary ? (
    <React.Fragment>
      <h2 className="WcMemberProfileContent__Subheading WcMemberProfileContent__Subheading__SummaryTitle">
        Candidate Summary
      </h2>
      <div className="WcMemberProfileContent__Summary">
        <ClampedText text={summary} />
      </div>
    </React.Fragment>
  ) : null;
};

MemberProfileCandidateSummary.propTypes = {
  summary: PropTypes.string
};
