import angular from 'angular';

import './styles.scss';
import templateUrl from './template.html';

class SettingsController {
  /*@ngInject*/
  constructor($q, AuthenticationService, API) {
    this.API = API;
    this.AuthenticationService = AuthenticationService;

    this.profileReadyPromiseDeferred = $q.defer();
    this.profileReadyPromise = this.profileReadyPromiseDeferred.promise;

    this.showCompensation = false;
    this.isMember = this.AuthenticationService.isMember();
    this.isClient = this.AuthenticationService.isClient();

    if (this.AuthenticationService.isMember()) {
      this.companiesLoading = true;
      API.retrieveLiveCompanies().then(
        this.setLiveCompanies.bind(this),
        this.API.handleError()
      );
    } else {
      this.companiesLoading = false;
    }

    this.profileLoading = true;
    this.updateLoading();

    API.User.get().then(this.setUser.bind(this), API.handleError());
    API.retrieveMyProfile().then(this.setProfile.bind(this), API.handleError());
  }

  setUser(user) {
    this.user = user;
  }

  setProfile(profile) {
    this.profile = profile;
    this.showCompensation = this.profile && !this.profile.noCompensation;
    this.profileLoading = false;
    this.profileReadyPromiseDeferred.resolve();
    this.updateLoading();
  }

  updateLoading() {
    this.loading = this.companiesLoading || this.profileLoading;
  }

  onCompanyPermissionChange(companies) {
    let blockedCompanyIds = companies
      .filter(company => !company.allowed)
      .map(company => company.id);
    if (this.profile) {
      this.API.restangularize(this.profile).patch({
        remunerationBlocked: blockedCompanyIds
      });
    }
  }

  onBlockedDefaultRemunerationChange(newDefault) {
    if (this.profile) {
      this.API.restangularize(this.profile).patch({
        remunerationBlockedDefault: newDefault
      });
    }
  }

  setLiveCompanies(liveCompanies) {
    return this.profileReadyPromise.then(() => {
      let liveCompaniesWithCompensationBlocked = liveCompanies.filter(
        company => company.compensationsBlocked
      );
      let liveCompaniesWithCompensationVisible = liveCompanies.filter(
        company => !company.compensationsBlocked
      );
      this.liveCompanies = liveCompaniesWithCompensationVisible;
      this.liveCompanies.map(company => {
        company.allowed =
          !this.profile ||
          this.profile.remunerationBlocked.indexOf(company.url) === -1;
        return company;
      });
      this.liveCompaniesWithCompensationBlocked = liveCompaniesWithCompensationBlocked;
      this.companiesLoading = false;
      this.updateLoading();
    });
  }

  onDefaultChangeClick() {
    if (this.profile) {
      this.onBlockedDefaultRemunerationChange(
        this.profile.remunerationBlockedDefault
      );
    }
  }

  onChange() {
    this.onCompanyPermissionChange(this.liveCompanies);
  }
}

export default angular
  .module('wcMobile.components.settings', [])
  .component('wcmSettings', {
    controller: SettingsController,
    templateUrl: templateUrl
  });
