import angular from 'angular';

import states from '../../../services/States';
import './styles.scss';
import templateUrl from './template.html';

export default angular
  .module('wcMobile.components.clientNavbar', [])
  .component('wcmClientNavbar', {
    /*@ngInject*/
    controller: function ClientNavbarController(AuthenticationService) {
      this.states = states;
      this.AuthenticationService = AuthenticationService;
    },
    templateUrl: templateUrl
  });
