import angular from 'angular';

import redirect from '../../../common/services/Redirect';
import states from '../../services/States';

/*@ngInject*/
function configureRoutes($stateProvider) {
  $stateProvider
    .state(states.CLIENTS, {
      abstract: true,
      resolve: {
        /*@ngInject*/
        redirect: function(RedirectService) {
          return RedirectService.redirectIfNotCompleteClient();
        }
      },
      template: `<ui-view class="WcRootView"></ui-view>`,
      url: '/clients'
    })

    .state(states.CLIENTS_PROFILE, {
      template: `<wcm-profile></wcm-profile>`,
      url: `/members/:id`
    })

    .state(states.CLIENTS_CHAT, {
      template: `<wcm-messenger></wcm-messenger>`,
      url: `/messages`
    })

    .state(states.CLIENTS_CONVERSATION, {
      template: `<wcm-messenger-conversation></wcm-messenger-conversation>`,
      url: `/messages/:id`
    })

    .state(states.CLIENT_DOCUMENT_DOWNLOAD, {
      url: '/documents/:id',
      template: `<wcm-client-document-download></<wcm-client-document-download>`
    })

    .state(states.CLIENTS_SEARCH, {
      template: `<wcm-search></wcm-search>`,
      url: `/search`
    })

    .state(states.CLIENTS_SETTINGS, {
      template: `<wcm-settings></wcm-settings>`,
      url: `/settings`
    });
}

export default angular
  .module('wcMobile.views.clients', [redirect.name])
  .config(configureRoutes);
